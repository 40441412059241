const detailRouter = {
  path: 'sale/:id',
  name: 'saleDetail',
  component: () => import('@/views/detail/rent/DetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.sale',
        pathName: 'sale',
      },
      {
        name: ':id',
        pathName: 'saleDetail',
      },
    ],
    title: '88go house',
    description: '',
    image: '',
    keepAlive: true,
    componentName: 'DetailView',
  },
};
export default detailRouter;

<template>
  <input
    :type="type ? type : 'text'"
    class="custom_input"
    :placeholder="placeholder"
    :id="id"
    aria-label="input"
    :class="{ 'disabled': disabled }"
    :disabled="disabled ? disabled : false"
    :readonly="readyonly ? readyonly : false"
    :value="value"
    :min="min"
    :max="max"
    @input="changeValue($event.target.value, index)"
  />
</template>

<script>
export default {
  name: 'DefaultInput',
  props: {
    placeholder: {
      type: String,
    },
    id: {
      type: String,
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    readyonly: {
      type: Boolean,
    },
    min: {
      type: Number,
      default: () => 0,
    },
    max: {
      type: Number,
    },
    value: [String, Number],
    index: {
      type: Number,
    },
  },
  emits: ['changeValue'],
  methods: {
    changeValue(value, index) {
      this.$emit('changeValue', value, index);
    },
  },
};
</script>

<template>
    <ul class="memberCenter_tabGroup">
      <li
          v-for="tab in list"
          :key="tab.id"
          class="memberCenter_tabGroup--tab"
          :class="{ 'choose': choose === tab.id }"
          @click="switchTab(tab)"
      >
        <span>{{ tab.label }}</span>
      </li>
    </ul>
</template>

<script>

export default {
  name: 'CategoryTab',
  props: {
    choose: {
      type: Number,
    },
    list: {
      type: Array,
    },
  },
  emits: ['update'],
  data() {
    return {
    };
  },
  methods: {
    switchTab(tab) {
      this.$emit('update', tab);
    },
  },
};
</script>

import metaBg from '@/assets/meta/meta_bg.png';

const mapLandRouter = {
  path: 'land/map',
  name: 'landMap',
  component: () => import('@/views/map/MapView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.land',
        pathName: 'land',
      },
      {
        name: 'breadcrumbList.mapSearch',
        pathName: 'landMap',
      },
    ],
    title: 'breadcrumbList.mapSearch',
    description: 'about.description1',
    bannerTitle: 'map.landMapSearch',
    bannerEnTitle: 'map.landMapSearchEn',
    image: metaBg,
    keepAlive: true,
    componentName: 'MapView',
  },
};
export default mapLandRouter;

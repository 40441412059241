const shopSaleDetailRouter = {
  path: 'shop/sale/:id',
  name: 'shopSaleDetail',
  component: () => import('@/views/detail/shop/ShopDetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'navbar.shop',
        pathName: 'shop',
      },
      {
        name: 'breadcrumbList.sale',
        pathName: 'office',
      },
      {
        name: ':id',
        pathName: 'shopSaleDetail',
      },
    ],
    title: '88go house',
    description: '',
    image: '',
    keepAlive: true,
    componentName: 'ShopDetailView',
  },
};
export default shopSaleDetailRouter;

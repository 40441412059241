<template>
  <div ref="select" class="select">
    <div class="custom_select" :class="{ 'disabled': disabled }" @click="openSelect">
      <input
        v-model="inputValue"
        class="custom_select--input"
        :class="{ 'disabled': disabled }"
        type="text"
        aria-label="select"
        :placeholder="placeholder"
        :disabled="disabled ? disabled : false"
        multiple
        readonly
        />
      <SvgIcon icon-class="arrow_down" class-name="icon custom_select--icon" :id="id"/>
    </div>
    <ul class="custom_options" :class="{ 'visible': visible }">
      <li
        class="custom_options--option"
        @click="choose(item)"
        v-for="item in list"
        :key="item.value"
        :class="{
          'activate': isChoose(item),
          'disable': item.disable
        }"
      >
        <div v-show="multiple" class="custom_checkbox--square" :class="{ 'choose': isChoose(item), 'disabled': item.disabled }"></div>
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DefaultSelect',
  props: {
    value: [String, Number, Array],
    placeholder: {
      type: String,
    },
    list: {
      type: Array,
    },
    id: {
      type: String,
      default: () => 'select',
    },
    disabled: {
      type: Boolean,
    },
    sort: {
      type: Number,
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['choose'],
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.clickOtherElement);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOtherElement);
  },
  computed: {
    inputValue() {
      let value = null;
      if (!this.multiple) {
        if (this.list) {
          this.list.forEach((item) => {
            if (this.value === item.value) {
              value = item.name;
            }
            if (item.secValue) {
              if (this.value === item.secValue) {
                value = item.name;
              }
            }
          });
        }
      } else if (this.multiple) {
        if (this.value?.length > 0) {
          if (this.list?.length > 0) {
            this.value.forEach((item, key) => {
              this.list.forEach((ele) => {
                if (item === ele.value) {
                  if (key === 0) {
                    value = '';
                    value += ele.name;
                  } else {
                    value += `,${ele.name}`;
                  }
                }
              });
            });
          }
        }
      }
      return value;
    },
  },
  methods: {
    isChoose(item) {
      let choose = false;
      if (!this.multiple) {
        if (item.value === parseInt(this.value, 10) || item.value === this.value) {
          choose = true;
        }
        if (item.secValue) {
          if (item.secValue === this.value) {
            choose = true;
          }
        }
      } else if (this.multiple) {
        if (this.value?.length > 0) {
          this.value.forEach((ele) => {
            if (ele === item.value) {
              choose = true;
            }
          });
        }
      }
      return choose;
    },
    clickOtherElement(e) {
      const btnStatus = this.$refs.select;
      if (btnStatus && !btnStatus.contains(e.target)) {
        if (this.visible) {
          this.visible = false;
          const icon = document.querySelector(`#${this.id}`);
          icon.removeAttribute('style');
        }
      }
    },
    choose(value) {
      if (value.disable) {
        return;
      }
      if (!this.multiple) {
        this.$emit('choose', value, this.sort);
        this.visible = false;
        const icon = document.querySelector(`#${this.id}`);
        icon.removeAttribute('style');
      } else {
        this.$emit('choose', value, this.sort);
      }
    },
    openSelect() {
      const icon = document.querySelector(`#${this.id}`);
      if (!this.disabled) {
        if (!this.visible) {
          icon.style.transform = 'rotate(180deg)';
          icon.style.color = '#FF781D';
        } else {
          icon.removeAttribute('style');
        }
        this.visible = !this.visible;
      }
    },
  },
};
</script>

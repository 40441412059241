import service from '@/plugins/axios';

// 會員首頁
const root = '/b/b101';

// 取得簡易資訊
export function simpleInfo(params) {
  return service({
    url: `${root}/simple_info`,
    method: 'get',
    params,
  });
}

// 取得方案資訊
export function plan(params) {
  return service({
    url: `${root}/plan`,
    method: 'get',
    params,
  });
}

// 取得刊登中房屋列表
export function publishList(params) {
  return service({
    url: `${root}/publish_list`,
    method: 'get',
    params,
  });
}

// 取得刊登中房屋列表
export function notification(params) {
  return service({
    url: `${root}/notification`,
    method: 'get',
    params,
  });
}

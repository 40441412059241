<template>
  <div class="home_searchView--mobile">
    <div class="home_searchView--content flex_column">
      <ul class="home_searchView--content--top">
        <li v-for="tab in tabs" :key="tab.id">
          <button
              type="button"
              class="w_100 memberCenter_tabs--tab"
              :class="{ 'choose': tab.id === tabType }"
              @click="changeTab(tab.id)"
          >
            {{ tab.label }}
          </button>
        </li>
      </ul>
      <div class="home_searchView--content--bottom">
        <div v-if="isMobile" class="keyword">
          <div class="keyword_input">
            <input
              type="text"
              aria-label="keyword"
              :placeholder="dynamicPlaceholder"
              :value="cond.key_word"
              id="keyword-input"
              @input="enterKeywords($event.target.value)"
              @keydown.enter="onSearch"
            />
            <button type="button" class="keyword_input--button" @click="onSearch">
              <SvgIcon icon-class="spotlight" class-name="icon" />
            </button>
          </div>
          <button type="button" class="avatar_button" @click="openFilter">
            <SvgIcon icon-class="filter" class-name="icon" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="home_bgView">
    <button
        class="avatar_button home_bgView--navigation prev"
        id="banner-prev"
        :class="{ 'avatar_button--md': isMobile }"
        @click.stop="prevEl"
    >
      <SvgIcon
          icon-class="arrow_left"
          class-name="form_imgView--icon"
      />
    </button>
    <swiper
        :navigation="{
          nextEl: '#banner-next',
          prevEl: '#banner-prev',
        }"
        :pagination="pagination"
        :slides-per-view="1"
        :space-between="12"
        :modules="modules"
        :autoplay="{
          delay: 5000,
        }"
        loop
    >
      <swiper-slide v-for="banner in carousel" :key="banner.id">
        <a class="home_bgView--link" :href="commonLink">
          <img :src="banner.image" alt="">
        </a>
      </swiper-slide>
    </swiper>
    <button
        class="avatar_button home_bgView--navigation next"
        id="banner-next"
        :class="{ 'avatar_button--md': isMobile }"
        @click.stop="nextEl"
    >
      <SvgIcon
          icon-class="arrow_right"
          class-name="form_imgView--icon"
      />
    </button>
    <div class="home_section">
      <div class="home_section--container">
        <div class="home_searchView">
          <div class="home_tabs">
            <button
              type="button"
              class="home_tabs--tab"
              :class="{ activate: item.id === tabType }"
              v-for="item in tabs"
              :key="item.id"
              @click="changeTab(item.id)"
            >
              <span class="home_tabs--text">{{ item.label }}</span>
            </button>
          </div>
          <div class="home_searchView--content">
            <div class="keyword">
              <div class="keyword_input">
                <input
                  type="text"
                  aria-label="keyword"
                  :placeholder="this.dynamicPlaceholder"
                  :value="cond.key_word"
                  id="keyword-input"
                  @input="enterKeywords($event.target.value)"
                  @keydown.enter="onSearch"
                />
                <button
                  type="button"
                  class="keyword_input--button"
                  @click="onSearch"
                >
                  <SvgIcon icon-class="spotlight" class-name="icon" />
                </button>
              </div>
              <button type="button" class="avatar_button" @click="openFilter">
                <SvgIcon icon-class="filter" class-name="icon" />
              </button>
            </div>
            <div class="home_filter">
              <div class="col-3 home_filter--spacing">
                <DefaultSelect
                  :value="cond.city"
                  :placeholder="$t('search.city')"
                  :list="cityList"
                  @choose="chooseCity"
                  id="banner-select1"
                />
              </div>
              <div class="col-3 home_filter--spacing">
                <DefaultSelect
                  v-if="tabType === 1 || tabType === 2"
                  :value="cond.house_categories"
                  :placeholder="$t('home.houseType')"
                  :list="tabType === 1 ? rentCategoryList : saleCategoryList"
                  multiple
                  @choose="chooseCategory"
                  id="banner-select2"
                />
                <DefaultSelect
                    v-else-if="tabType === 3"
                    :value="cond.case_type"
                    :placeholder="$t('search.caseType')"
                    :list="casesList"
                    multiple
                    @choose="chooseCase"
                    id="banner-select2"
                />
                <DefaultSelect
                    v-else-if="tabType === 4"
                    :value="cond.factory_types"
                    :placeholder="$t('publish.form.factoryType')"
                    :list="factoryCategoryList"
                    multiple
                    @choose="chooseFactoryType"
                    id="banner-select2"
                />
                <DefaultSelect
                    v-else-if="tabType === 5"
                    :value="cond.land_types"
                    :placeholder="$t('publish.form.landType')"
                    :list="landCategoryList"
                    multiple
                    @choose="chooseLandType"
                    id="banner-select2"
                />
                <DefaultSelect
                    v-else-if="tabType === 6"
                    :value="cond.office_category"
                    :placeholder="$t('publish.form.officeCategory')"
                    :list="officeCategoryList"
                    multiple
                    @choose="chooseOfficeCategory"
                    id="banner-select2"
                />
                <DefaultSelect
                    v-else-if="tabType === 7"
                    :value="cond.storefront_category"
                    :placeholder="$t('publish.form.shopCategory')"
                    :list="shopCategoryList"
                    multiple
                    @choose="chooseShopCategory"
                    id="banner-select2"
                />
              </div>
              <div class="col-3 home_filter--spacing">
                <DefaultSelect
                    v-if="tabType === 1 || tabType === 2 || tabType === 3"
                    :value="cond.house_types"
                    :placeholder="$t('publish.form.housingType')"
                    :list="typeList"
                    multiple
                    @choose="chooseType"
                    id="banner-select4"
                />
                <DefaultSelect
                    v-else-if="tabType === 4 || tabType === 5"
                    :value="cond.type"
                    :placeholder="$t('memberCenter.publishType')"
                    :list="publishTypeList"
                    @choose="choosePublishType"
                    id="banner-select4"
                />
                <DefaultSelect
                    v-else-if="tabType === 6"
                    :value="cond.office_type"
                    :placeholder="$t('publish.form.officeType')"
                    :list="officeTypeList"
                    multiple
                    @choose="chooseOfficeType"
                    id="banner-select4"
                />
                <DefaultSelect
                    v-else-if="tabType === 7"
                    :value="cond.storefront_type"
                    :placeholder="$t('publish.form.shopType')"
                    :list="officeTypeList"
                    multiple
                    @choose="chooseShopType"
                    id="banner-select4"
                />
              </div>
              <div class="col-3 home_filter--spacing">
                <DefaultSelect
                  v-if="tabType === 1 || tabType === 2 || tabType === 3"
                  :value="cond.room_num"
                  :placeholder="$t('home.housingLayout')"
                  :list="roomList"
                  multiple
                  @choose="chooseRoom"
                  id="banner-select3"
                />
                <DefaultSelect
                  v-else-if="tabType === 6 || tabType === 7"
                  :value="cond.type"
                  :placeholder="$t('memberCenter.publishType')"
                  :list="publishTypeList"
                  @choose="choosePublishType"
                  id="banner-select3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSelect from '@/components/select/DefaultSelect.vue';
import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { carousels } from '@/plugins/api/a/a107';

export default {
  name: 'HomeBanner',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    cond: {
      type: Object,
    },
    tabType: {
      type: Number,
    },
  },
  emits: [
    'enter',
    'switch',
    'updateCity',
    'updateCategory',
    'updateCase',
    'updateFactoryType',
    'updateLandType',
    'updateOfficeCategory',
    'updateShopCategory',
    'updateOfficeType',
    'updateShopType',
    'updatePublishType',
    'updateRoom',
    'updateType',
    'openFilter',
    'search',
  ],
  computed: {
    ...mapGetters(['lang', 'cityList', 'isMobile', 'carousel']),
    commonLink() {
      return `https://88gohouse.com/${this.lang}/plan`;
    },
  },
  components: {
    DefaultSelect,
    Swiper,
    SwiperSlide,
    Navigation,
    Pagination,
  },
  setup() {
    return {
      pagination: {
        clickable: true,
        renderBullet: (index, className) => `<span class="${className}"></span>`,
      },
      modules: [Autoplay, Navigation, Pagination],
    };
  },
  data() {
    return {
      dynamicPlaceholder: '',
      rentCategoryList: [
        {
          value: 2,
          name: this.$t('house_info.house_category.2'),
        },
        {
          value: 3,
          name: this.$t('house_info.house_category.3'),
        },
        {
          value: 4,
          name: this.$t('house_info.house_category.4'),
        },
      ],
      saleCategoryList: [
        {
          value: 1,
          name: this.$t('house_info.house_category.1'),
        },
        {
          value: 5,
          name: this.$t('house_info.house_category.5'),
        },
        {
          value: 6,
          name: this.$t('house_info.house_category.6'),
        },
        {
          value: 7,
          name: this.$t('house_info.house_category.7'),
        },
      ],
      landCategoryList: [
        {
          value: 1,
          name: this.$t('land.type.1'),
        },
        {
          value: 2,
          name: this.$t('land.type.2'),
        },
        {
          value: 3,
          name: this.$t('land.type.3'),
        },
        {
          value: 4,
          name: this.$t('land.type.4'),
        },
        {
          value: 5,
          name: this.$t('land.type.5'),
        },
        {
          value: 6,
          name: this.$t('land.type.6'),
        },
      ],
      factoryCategoryList: [
        {
          value: 1,
          name: this.$t('factory.type.1'),
        },
        {
          value: 2,
          name: this.$t('factory.type.2'),
        },
        {
          value: 3,
          name: this.$t('factory.type.3'),
        },
        {
          value: 4,
          name: this.$t('factory.type.4'),
        },
        {
          value: 5,
          name: this.$t('factory.type.5'),
        },
      ],
      officeCategoryList: [
        {
          value: 1,
          name: this.$t('office.category.1'),
        },
        {
          value: 2,
          name: this.$t('office.category.2'),
        },
      ],
      shopCategoryList: [
        {
          value: 1,
          name: this.$t('shop.category.1'),
        },
        {
          value: 2,
          name: this.$t('shop.category.2'),
        },
        {
          value: 3,
          name: this.$t('shop.category.3'),
        },
        {
          value: 4,
          name: this.$t('shop.category.4'),
        },
        {
          value: 5,
          name: this.$t('shop.category.5'),
        },
        {
          value: 6,
          name: this.$t('shop.category.6'),
        },
        {
          value: 7,
          name: this.$t('shop.category.7'),
        },
        {
          value: 8,
          name: this.$t('shop.category.8'),
        },
      ],
      officeTypeList: [
        {
          value: 1,
          name: this.$t('office.type.1'),
        },
        {
          value: 2,
          name: this.$t('office.type.2'),
        },
        {
          value: 3,
          name: this.$t('office.type.3'),
        },
        {
          value: 4,
          name: this.$t('office.type.4'),
        },
        {
          value: 5,
          name: this.$t('office.type.5'),
        },
      ],
      typeList: [
        {
          value: 1,
          name: this.$t('house_info.house_type.1'),
        },
        {
          value: 2,
          name: this.$t('house_info.house_type.2'),
        },
        {
          value: 3,
          name: this.$t('house_info.house_type.3'),
        },
        {
          value: 4,
          name: this.$t('house_info.house_type.4'),
        },
      ],
      casesList: [
        {
          value: 1,
          name: this.$t('new_house.case_type.1'),
        },
        {
          value: 2,
          name: this.$t('new_house.case_type.2'),
        },
        {
          value: 3,
          name: this.$t('new_house.case_type.3'),
        },
      ],
      roomList: [
        {
          value: 1,
          name: `1${this.$t('search.room')}`,
        },
        {
          value: 2,
          name: `2${this.$t('search.room')}`,
        },
        {
          value: 3,
          name: `3${this.$t('search.room')}`,
        },
        {
          value: 4,
          name: `4${this.$t('search.roomUp')}`,
        },
      ],
      orderList: [
        {
          value: '1',
          name: this.$t('search.order.1'),
        },
        {
          value: '2',
          name: this.$t('search.order.2'),
        },
        {
          value: '3',
          name: this.$t('search.order.3'),
        },
        {
          value: '4',
          name: this.$t('search.order.4'),
        },
        {
          value: '5',
          name: this.$t('search.order.5'),
        },
        {
          value: '6',
          name: this.$t('search.order.6'),
        },
      ],
      publishTypeList: [
        {
          value: 3,
          name: this.$t('breadcrumbList.rent'),
        },
        {
          value: 4,
          name: this.$t('breadcrumbList.sale'),
        },
      ],
      timer: null,
    };
  },
  watch: {
    lang(value, oldValue) {
      if (value !== oldValue) {
        this.dynamicAddInputPlaceholder();
      }
    },
    tabType(value) {
      switch (value) {
        case 4:
          this.publishTypeList = [
            {
              value: 3,
              name: this.$t('breadcrumbList.rent'),
            },
            {
              value: 4,
              name: this.$t('breadcrumbList.sale'),
            },
          ];
          break;
        case 5:
          this.publishTypeList = [
            {
              value: 5,
              name: this.$t('breadcrumbList.rent'),
            },
            {
              value: 6,
              name: this.$t('breadcrumbList.sale'),
            },
          ];
          break;
        case 6:
          this.publishTypeList = [
            {
              value: 7,
              name: this.$t('breadcrumbList.rent'),
            },
            {
              value: 8,
              name: this.$t('breadcrumbList.sale'),
            },
          ];
          break;
        case 7:
          this.publishTypeList = [
            {
              value: 9,
              name: this.$t('breadcrumbList.rent'),
            },
            {
              value: 10,
              name: this.$t('breadcrumbList.sale'),
            },
          ];
          break;
        default:
          break;
      }
    },
  },
  created() {
    this.getCarousel();
  },
  mounted() {
    this.dynamicAddInputPlaceholder();
    this.$nextTick(() => {
      const input = document.querySelector('#keyword-input');
      input.addEventListener('input', (e) => {
        if (e.target.value.length === 0) {
          this.dynamicAddInputPlaceholder();
        } else {
          this.clearPlaceholder();
        }
      });
    });
  },
  beforeUnmount() {
    this.clearPlaceholder();
  },
  methods: {
    async clearPlaceholder() {
      clearTimeout(this.timer);
      clearInterval(this.timer);
      this.timer = null;
      this.dynamicPlaceholder = '';
    },
    async dynamicAddInputPlaceholder() {
      await this.clearPlaceholder();
      const arr = this.$t('home.keywordPlaceholder').split('');
      for (let i = 0; i < arr.length; i++) {
        this.timer = setTimeout(() => {
          this.dynamicPlaceholder += arr[i];
          if (i === arr.length - 1) {
            clearTimeout(this.timer);
            // 到新增到最後字倒回刪除循環
            this.dynamicSplitInputPlaceholder();
          }
        }, i * 500);
      }
    },
    async dynamicSplitInputPlaceholder() {
      clearInterval(this.timer);
      this.timer = null;
      this.timer = setInterval(() => {
        this.dynamicPlaceholder = this.dynamicPlaceholder.substring(0, this.dynamicPlaceholder.length - 1);
        if (this.dynamicPlaceholder.length === 0) {
          clearInterval(this.timer);
          setTimeout(() => {
            // 再新增到最後字循環
            this.dynamicAddInputPlaceholder();
          }, 500);
        }
      }, 500);
    },
    getCarousel() {
      const fullWidth = document.documentElement.clientWidth;
      carousels({ limit: 5, type: fullWidth < 991 ? 2 : 1 }).then((res) => {
        const { success, data } = res;
        if (success) {
          this.$store.commit('UPDATE_CAROUSEL', data);
        }
      }).catch(() => {});
    },
    enterKeywords(value) {
      this.$emit('enter', value);
    },
    changeTab(id) {
      this.$emit('switch', id);
    },
    chooseCity(city) {
      this.$emit('updateCity', city.value);
    },
    chooseCategory(category) {
      this.$emit('updateCategory', category.value);
    },
    chooseCase(value) {
      this.$emit('updateCase', value.value);
    },
    chooseFactoryType(value) {
      this.$emit('updateFactoryType', value.value);
    },
    chooseLandType(value) {
      this.$emit('updateLandType', value.value);
    },
    chooseOfficeCategory(value) {
      this.$emit('updateOfficeCategory', value.value);
    },
    chooseShopCategory(value) {
      this.$emit('updateShopCategory', value.value);
    },
    chooseOfficeType(value) {
      this.$emit('updateOfficeType', value.value);
    },
    chooseShopType(value) {
      this.$emit('updateShopType', value.value);
    },
    choosePublishType(value) {
      this.$emit('updatePublishType', value.value);
    },
    chooseRoom(room) {
      this.$emit('updateRoom', room.value);
    },
    chooseType(type) {
      this.$emit('updateType', type.value);
    },
    openFilter() {
      this.$emit('openFilter');
    },
    onSearch() {
      this.$emit('search');
    },
  },
};
</script>

<template>
  <NoResult v-if="identity === 'guest'" :show="is_match" />
  <div class="home_cardView">
    <div
        class="home_cardView--col"
        v-for="obj in suitableLand"
        :key="obj.id"
    >
      <LandCard
          :object="obj"
          type="land_5"
          @favorite="
              () => {
                favoriteClick(obj);
              }
            "
      />
    </div>
  </div>
  <div v-show="!suitableLoading && suitableNodataText" class="w_100 text_center" style="padding: 12px;">
    <span
        class="home_contentView--nodata"
    >{{ suitableNodataText }}</span
    >
  </div>
  <div v-show="form.page !== maxPage" class="home_cardView--btnView">
    <div class="home_cardView--btn">
      <button class="w_100 more_button" type="button" @click="showMore">
        <span v-show="suitableLoading" class="loader"></span>
        {{ $t('home.more') }}
      </button>
    </div>
  </div>
</template>

<script>
import NoResult from '@/views/home/components/match/NoResult.vue';
import LandCard from '@/components/card/LandCard.vue';
import { suitableLand } from '@/plugins/api/a/a109';
import { mapGetters } from 'vuex';
import {
  favorite,
  unFavorite,
} from '@/plugins/api/b/b102';

export default {
  name: 'SuitableLand',
  props: {
    choose: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'lang', 'identity', 'suitableLand', 'bestLand']),
  },
  components: {
    LandCard,
    NoResult,
  },
  data() {
    return {
      suitableLoading: false,
      suitableNodataText: null,
      form: {
        page: 1,
        limit: 8,
        lang: null,
      },
      is_match: false,
      maxPage: 1,
    };
  },
  watch: {
    choose(value, oldValue) {
      if (value !== oldValue) {
        this.form.page = 1;
        this.$store.commit('UPDATE_SUITABLE_LAND', []);
        this.getSuitableList();
      }
    },
  },
  created() {
    if (this.suitableLand.length === 0) {
      this.getSuitableList();
    }
  },
  methods: {
    getSuitableList() {
      this.is_match = false;
      this.suitableNodataText = null;
      this.suitableLoading = true;
      this.form.lang = this.lang;
      this.form.type = this.choose;
      suitableLand(this.form).then((res) => {
        if (res.success) {
          if (this.form.page === 1) {
            this.$store.commit('UPDATE_SUITABLE_LAND', res.data.list);
            if (this.suitableLand.length === 0) {
              this.suitableNodataText = this.$t('noDataText.noData');
            }
          } else {
            const arr = JSON.parse(JSON.stringify(this.suitableLand)).concat(res.data.list);
            this.$store.commit('UPDATE_SUITABLE_LAND', arr);
          }
          if (res.data.is_match) this.is_match = false;
          else this.is_match = true;
          this.maxPage = res.data.max_page;
        }
        this.suitableLoading = false;
      }).catch(() => {
        this.suitableLoading = false;
      });
    },
    favoriteClick(obj) {
      if (obj.favorite) {
        unFavorite(obj.id).then((res) => {
          const { success } = res;
          if (success) {
            obj.favorite = false;
            this.$toast.success(this.$t('noticeText.removeFavorite'));
            if (this.bestLand.length > 0) {
              this.bestLand.forEach((item) => {
                if (item.id === obj.id) {
                  item.favorite = false;
                }
              });
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      } else {
        favorite({
          id: obj.id,
        }).then((res) => {
          const { success } = res;
          if (success) {
            obj.favorite = true;
            this.$toast.success(this.$t('noticeText.addFavorite'));
            if (this.bestLand.length > 0) {
              this.bestLand.forEach((item) => {
                if (item.id === obj.id) {
                  item.favorite = true;
                }
              });
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    },
    showMore() {
      this.form.page += 1;
      this.getSuitableList();
    },
  },
};
</script>

const officeRentDetailRouter = {
  path: 'office/rent/:id',
  name: 'officeRentDetail',
  component: () => import('@/views/detail/office/OfficeDetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'navbar.office',
        pathName: 'office',
      },
      {
        name: 'breadcrumbList.rent',
        pathName: 'office',
      },
      {
        name: ':id',
        pathName: 'officeRentDetail',
      },
    ],
    title: '88go house',
    description: '',
    image: '',
    keepAlive: true,
    componentName: 'OfficeDetailView',
  },
};
export default officeRentDetailRouter;

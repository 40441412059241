const factoryRentDetailRouter = {
  path: 'factory/rent/:id',
  name: 'factoryRentDetail',
  component: () => import('@/views/detail/factory/FactoryDetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.factory',
        pathName: 'factory',
      },
      {
        name: 'breadcrumbList.rent',
        pathName: 'factory',
      },
      {
        name: ':id',
        pathName: 'factoryRentDetail',
      },
    ],
    title: '88go house',
    description: '',
    image: '',
    keepAlive: true,
    componentName: 'FactoryDetailView',
  },
};
export default factoryRentDetailRouter;

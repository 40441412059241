<template>
  <div v-show="open" class="float" :class="{ 'skeleton_loading': isLoad }">
    <div class="float_banner">
        <img v-if="!isLoad" :src="ad.image?.length > 0 ? ad?.image : ''" :alt="ad.id" @click="redirectUrl">
        <button type="button" class="float_banner--icon" @click="close">
          <SvgIcon icon-class="close" />
        </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FloatAd',
  props: {
    isLoad: {
      type: Boolean,
    },
    ad: {
      type: Object,
    },
    open: {
      type: Boolean,
    },
  },
  emits: ['close'],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['lang']),
    commonLink() {
      return `https://88gohouse.com/${this.lang}/identity`;
    },
  },
  methods: {
    redirectUrl() {
      window.location.href = this.commonLink;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

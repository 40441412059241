const detailRouter = {
  path: 'rent/:id',
  name: 'rentDetail',
  component: () => import('@/views/detail/rent/DetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.rent',
        pathName: 'rent',
      },
      {
        name: ':id',
        pathName: 'rentDetail',
      },
    ],
    title: '88go house',
    description: '',
    image: '',
    keepAlive: true,
    componentName: 'DetailView',
  },
};
export default detailRouter;

<template>
    <div class="sidebar">
      <template v-if="!isMobile">
        <div class="sidebar_content">
            <a v-show="!isMobile" href="https://www.facebook.com/88go.house" class="sidebar_icons">
                <SvgIcon icon-class="facebook" class-name="sidebar_icons--icon sidebar_icons--fb" />
            </a>
            <a v-show="!isMobile" href="https://www.instagram.com/88gohouse/" class="sidebar_icons">
                <SvgIcon icon-class="instagram" class-name="sidebar_icons--icon" />
            </a>
            <!-- <a v-show="!isMobile" href="#" class="sidebar_icons">
                <SvgIcon icon-class="messager" class-name="sidebar_icons--icon" />
            </a> -->
            <a ref="mail" v-show="isLogin" href="#" class="sidebar_icons" @click.prevent="showNotification = !showNotification">
                <SvgIcon icon-class="mail" class-name="sidebar_icons--icon sidebar_icons--mail" />
                <div v-show="detail.house !== 0 || detail.message !== 0 || detail.store !== 0" class="sidebar_icons--dot"></div>
                <div
                  v-show="showNotification"
                  class="sidebar_mail"
                  :class="{ 'fadeIn': showNotification }"
                  :style="{
                    'min-width': lang === 'cn' ? '120px' : '185px',
                    'top': isMobile ? '-120px' : identity === 'host' ? '-25%' : '-60%',
                    'right': isMobile ? '0' : '64px',
                  }"
                >
                  <button type="button" class="sidebar_mail--btn" @click="redirectPath(`${identity}Message`)">
                    <div v-show="detail.message !== 0" class="sidebar_mail--dot"></div>
                    <div class="sidebar_mail--textView">
                      <span class="sidebar_mail--text">
                        {{ $t('memberCenter.websiteNotification') }}
                        {{ detail.message !== 0 ? `(${detail.message})` : '' }}
                      </span>
                    </div>
                  </button>
                  <button type="button" class="sidebar_mail--btn" @click="redirectPath(`${identity}HouseQA`)">
                    <div v-show="detail.house !== 0" class="sidebar_mail--dot"></div>
                    <div class="sidebar_mail--textView">
                      <span class="sidebar_mail--text">
                        {{ $t('memberCenter.houseQA') }}
                        {{ detail.house !== 0 ? `(${detail.house})` : '' }}
                      </span>
                    </div>
                  </button>
                  <button v-if="identity !== 'host'" type="button" class="sidebar_mail--btn" @click="redirectPath(`${identity}StoreQA`)">
                    <div v-show="detail.store !== 0" class="sidebar_mail--dot"></div>
                    <div class="sidebar_mail--textView">
                      <span class="sidebar_mail--text">
                        {{ $t('memberCenter.storeQA') }}
                        {{ detail.store !== 0 ? `(${detail.store})` : '' }}
                      </span>
                    </div>
                  </button>
                  <button v-if="identity === 'guest'" type="button" class="sidebar_mail--btn" @click="redirectPath(`${identity}HouseMatching`)">
                    <div v-show="detail.prefer_msg !== 0" class="sidebar_mail--dot"></div>
                    <div class="sidebar_mail--textView">
                      <span class="sidebar_mail--text">
                        {{ $t('memberCenter.matchingObject') }}
                        {{ detail.store !== 0 ? `(${detail.prefer_msg})` : '' }}
                      </span>
                    </div>
                  </button>
                </div>
            </a>
        </div>
      </template>
      <template v-else>
        <a ref="mail" v-show="isLogin" href="#" class="sidebar_icons" @click.prevent="showNotification = !showNotification">
              <SvgIcon icon-class="mail" class-name="sidebar_icons--icon sidebar_icons--mail" />
              <div v-show="detail.house !== 0 || detail.message !== 0 || detail.store !== 0" class="sidebar_icons--dot"></div>
              <div
                v-show="showNotification"
                class="sidebar_mail"
                :class="{ 'fadeIn': showNotification }"
                :style="{
                  'min-width': lang === 'cn' ? '120px' : '185px',
                  'top': isMobile ? identity === 'guest' ? '-154px' : '-120px' : identity === 'host' ? '-25%' : '-60%',
                  'right': isMobile ? '0' : '64px',
                }"
              >
                <button type="button" class="sidebar_mail--btn" @click="redirectPath(`${identity}Message`)">
                  <div v-show="detail.message !== 0" class="sidebar_mail--dot"></div>
                  <div class="sidebar_mail--textView">
                    <span class="sidebar_mail--text">
                      {{ $t('memberCenter.websiteNotification') }}
                      {{ detail.message !== 0 ? `(${detail.message})` : '' }}
                    </span>
                  </div>
                </button>
                <button type="button" class="sidebar_mail--btn" @click="redirectPath(`${identity}HouseQA`)">
                  <div v-show="detail.house !== 0" class="sidebar_mail--dot"></div>
                  <div class="sidebar_mail--textView">
                    <span class="sidebar_mail--text">
                      {{ $t('memberCenter.houseQA') }}
                      {{ detail.house !== 0 ? `(${detail.house})` : '' }}
                    </span>
                  </div>
                </button>
                <button v-if="identity !== 'host'" type="button" class="sidebar_mail--btn" @click="redirectPath(`${identity}StoreQA`)">
                  <div v-show="detail.store !== 0" class="sidebar_mail--dot"></div>
                  <div class="sidebar_mail--textView">
                    <span class="sidebar_mail--text">
                      {{ $t('memberCenter.storeQA') }}
                      {{ detail.store !== 0 ? `(${detail.store})` : '' }}
                    </span>
                  </div>
                </button>
                <button v-if="identity === 'guest'" type="button" class="sidebar_mail--btn" @click="redirectPath(`${identity}HouseMatching`)">
                  <div v-show="detail.prefer_msg !== 0" class="sidebar_mail--dot"></div>
                  <div class="sidebar_mail--textView">
                    <span class="sidebar_mail--text">
                      {{ $t('memberCenter.matchingObject') }}
                      {{ detail.store !== 0 ? `(${detail.prefer_msg})` : '' }}
                    </span>
                  </div>
                </button>
              </div>
        </a>
      </template>
        <button v-show="showFloatingBall" type="button" class="scroll_ball" @click="onScroll">
            <SvgIcon icon-class="arrow_top" class-name="icon" />
        </button>
    </div>
</template>

<script>
import { notification } from '@/plugins/api/b/b101';
import { mapGetters } from 'vuex';

export default {
  name: 'SideBar',
  data() {
    return {
      showFloatingBall: false,
      showNotification: false,
      detail: {},
      // timer: null,
    };
  },
  watch: {
    identity: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          if (this.isLogin) {
            this.getNotification();
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isLogin', 'lang', 'identity']),
  },
  mounted() {
    document.addEventListener('click', this.clickOtherElement);
    document.addEventListener('scroll', () => {
      if (window.scrollY >= 200) {
        this.showFloatingBall = true;
      } else {
        this.showFloatingBall = false;
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOtherElement);
    // document.removeEventListener('scroll', () => {
    //   if (window.scrollY > 200) {
    //     this.showFloatingBall = true;
    //   } else {
    //     this.showFloatingBall = false;
    //   }
    // });
  },
  methods: {
    clickOtherElement(e) {
      const mailStatus = this.$refs.mail;
      if (mailStatus && !mailStatus.contains(e.target)) {
        if (this.showNotification) {
          this.showNotification = false;
        }
      }
    },
    onScroll() {
      window.scrollTo(
        {
          top: 0,
          behavior: 'smooth',
        },
      );
    },
    redirectPath(name) {
      this.$router.push({ name });
    },
    getNotification() {
      notification().then((res) => {
        const { success, data } = res;
        if (success) {
          this.detail = data;
        }
      }).catch(() => {});
    },
  },
};
</script>

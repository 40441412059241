import metaBg from '@/assets/meta/meta_bg.png';

const videoRouter = {
  path: 'video',
  component: () => import('@/views/video/VideoLayout.vue'),
  children: [
    {
      path: ':id?',
      name: 'video',
      component: () => import('@/views/video/page/VideoPage.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.video',
            pathName: 'video',
          },
        ],
        title: 'video.title',
        enTitle: 'video.enTitle',
        description: 'about.description1',
        image: metaBg,
        keepAlive: true,
        componentName: 'VideoLayout',
      },
    },
  ],
};
export default videoRouter;

import metaBg from '@/assets/meta/meta_bg.png';

const mapSaleRouter = {
  path: 'sale/map',
  name: 'saleMap',
  component: () => import('@/views/map/MapView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.sale',
        pathName: 'sale',
      },
      {
        name: 'breadcrumbList.mapSearch',
        pathName: 'saleMap',
      },
    ],
    title: 'breadcrumbList.mapSearch',
    description: 'about.description1',
    bannerTitle: 'map.SaleMapSearch',
    bannerEnTitle: 'map.SaleMapSearchEn',
    image: metaBg,
    keepAlive: true,
    componentName: 'MapView',
  },
};
export default mapSaleRouter;

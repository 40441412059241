import metaBg from '@/assets/meta/meta_bg.png';

const friendsRulesRouter = {
  path: 'friend_recommendation_rules',
  component: () => import('@/views/info/InfoView.vue'),
  children: [
    {
      path: '',
      name: 'friendRecommendationRules',
      component: () => import('@/views/info/agent/AgentView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'memberCenter.friendsRule',
            pathName: 'friendRecommendationRules',
          },
        ],
        title: 'friendRules.title',
        description: 'about.description1',
        image: metaBg,
        bannerTitle: 'friendRules.title',
        bannerEnTitle: 'friendRules.enTitle',
      },
    },
  ],
};
export default friendsRulesRouter;

import metaBg from '@/assets/meta/meta_bg.png';

const saleRouter = {
  path: 'sale',
  name: 'sale',
  component: () => import('@/views/rent/RentView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.sale',
        pathName: 'sale',
      },
    ],
    title: 'sale.title',
    enTitle: 'sale.enTitle',
    description: 'about.description1',
    image: metaBg,
    recommendedTitle: 'rent.recommended',
    allTitle: 'rent.forSale',
    keepAlive: true,
    componentName: 'RentView',
  },
};
export default saleRouter;

import metaBg from '@/assets/meta/meta_bg.png';

const rentRouter = {
  path: 'rent',
  name: 'rent',
  component: () => import('@/views/rent/RentView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.rent',
        pathName: 'rent',
      },
    ],
    title: 'rent.title',
    enTitle: 'rent.enTitle',
    description: 'about.description1',
    image: metaBg,
    recommendedTitle: 'rent.recommended',
    allTitle: 'rent.forRent',
    keepAlive: true,
    componentName: 'RentView',
  },
};
export default rentRouter;

const en = {
  title: '88go house house search website',
  description: 'Find Your Home at 88go!',
  breadcrumbList: {
    home: 'Home',
    rent: 'Rent',
    sale: 'Sale',
    preSale: 'New Project',
    factory: 'Factory',
    land: 'Land',
    mapSearch: 'Map Search',
    choose88go: 'Why Choose 88go',
    ourPlan: 'Our Plans',
    upgradeAccount: 'Upgrade Account Plan',
    agent: 'Agent Regulations',
    disclaimer: 'Disclaimer',
    policy: 'Terms and Privacy Policy',
    publish: 'Publish My Listing',
    memberHome: 'Member Home',
    account: 'Account',
    changePhone: 'Change Phone Number',
    changePwd: 'Change Password',
    message: 'Message',
    messageContent: 'Message Content',
    houseQA: 'House Q&A',
    impeach: 'Report',
    history: 'History',
    favoriteObjects: 'Favorite',
    transactionRecords: 'Transaction',
    promotions: 'Promotions',
    activityDetails: 'Activity Details',
    performanceReport: 'Performance Report',
    accountManagement: 'Account Management',
    addAccount: 'Add Account',
    transaction: 'Transaction',
    plansManagement: 'Plans',
    upgradeManagement: 'Upgrade',
    cashManagement: 'Cash',
    purchasePlan: 'Purchase Plan',
    paymentManagement: 'Payment',
    addNewCard: 'Add Card',
    publishManagement: 'Publishing',
    housesClosed: 'Closed',
    housesEdit: 'Unlisted',
    removedHouses: 'Sold',
    renew: 'Renew My Listing',
    singlePosting: 'Single Listing',
    rentalPlanPublication: 'Rental Plan Publishing',
    salePlanPublication: 'Sale Plan Publishing',
    editListing: 'Edit Listing',
    improveEffect: 'Improve Effectiveness',
    storeManagement: 'Store',
    storeQA: 'Store Q&A',
    flyers: 'Flyer Creation',
    improve: 'Improve Effectiveness',
    certification: 'Certification',
    workData: 'Work Information',
    subAccount: 'Subaccount',
    advertisement: 'Advertisement',
    applyForAds: 'Apply For Ads',
    forum: 'Forum',
    myArticles: 'My articles',
    publishArticle: 'Publish',
    editArticle: 'Edit',
    myRequirements: 'Requirements',
    advertisementApplicationRecord: 'Application Records',
    video: 'Videos',
    businessManagement: 'Business',
  },
  navbar: {
    publish: 'Publish',
    rent: 'Rent',
    sale: 'Sale',
    preSale: 'New Project',
    factory: 'Factory',
    land: 'Land',
    house: 'House',
    office: 'Office',
    shop: 'Shop',
    register: 'Register',
    login: 'Login',
    memberCenter: 'Member Center',
    signOut: 'Sign Out',
    hello: 'Hello',
    member: 'Member',
    forum: 'Forum',
    video: 'Videos',
    join: 'Account',
    objectType: 'Object Type',
  },
  login: {
    phoneNumber: 'Phone',
    phonePlaceholder: 'Enter your phone number',
    pwd: 'Password',
    pwdplaceholder: 'Please enter a password of 8 to 12 characters including alphanumeric characters',
    remember: 'Remember Me',
    forget: 'Forgot Password',
    login: 'Login',
    noAccount: 'Don\'t have an account?',
    register: 'Register',
    accountLogin: 'Login with Social Account',
    socialAccountNotice: 'Social account login must be bound to the member center before it can be used.',
  },
  register: {
    registrationStatus: 'Please Select Your Registration Status',
    buyer: 'Buyer/Tenant',
    choose: 'Choose',
    owner: 'Owner/Agent',
    estateAgent: 'Estate Agent/Property Agent',
    estateAgency: 'Real Estate Agency',
    social: 'Register with Social Account',
    hostWarningText: '*Legal brokers are not allowed to register as "Owner/Agent"',
    agentWarningText: '*Please specify the brokerage name. Unauthorized brokers are not allowed to conduct business on 88go',
    emergencyText: 'In case of account issues/advertisement confirmation/website activities, when 88g0 cannot contact the member, an emergency contact person may be notified',
    hasAccount: 'Already have an account?',
    login: 'Login',
    phoneNumber: 'Phone',
    phonePlaceholder: 'Enter your phone number',
    pwd: 'Password',
    pwdplaceholder: 'Please enter a password of 8 to 12 characters including alphanumeric characters (excluding capital letters)',
    confirmPwd: 'Confirm Password',
    name: 'Name',
    nameplaceholder: 'Enter your name',
    emailplaceholder: 'Enter Email',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    ready: 'I have read and understood',
    terms: 'Terms and Privacy Policy',
    and: 'and',
    disclaimer: 'Disclaimer',
    ready2: 'and its meaning, agree to the terms and conditions, and are willing to comply with various rules and regulations of the website in cash and in the future',
    register: 'Register',
    accountOwnership: 'Account Ownership',
    companyAccount: 'Company Account',
    branchAccount: 'Branch Account',
    other: 'Other',
    workArea: 'Work Area',
    company: 'Company',
    branch: 'Branch',
    position: 'Position',
    brokerageName: 'Brokerage Name',
    brokerageNameplaceholder: 'Enter Brokerage Name',
    storeType: 'Store Type',
    directSale: 'Direct Store',
    franchise: 'Franchise Store',
    invoice: 'Invoice',
    invoiceType: {
      donate: 'Donate to Charity Organizations',
      donateAlert: '*Donations cannot be reclaimed.',
      donateDes: '(Recipient Organization: Qingpu Education Foundation)',
      send: 'Send to Address',
      sendDes: '(Prize redemption is processed on the 26th of each month. If you win, you will be notified via SMS and sent by registered mail.)',
      tax: 'Tax ID',
      taxAlert: '*Please fill in carefully to avoid loss during mailing.',
      taxDes: '(Sent by regular mail, longer delivery time)',
      receipt: 'Electronic Invoice/Mobile Device',
      receiptDes: '(Issued based on the "Trial Operation Points of Electronic Invoice for Consumer Channels")',
      buyer: 'Buyer',
      buyerplaceholder: 'Enter Name',
      contactPhone: 'Contact Number',
      contactPhonePlaceholder: 'Please enter contact number',
      addressplaceholder: 'Enter Complete Mailing Address',
      taxplaceholder: 'Enter Tax ID',
      mobileBarcode: 'Mobile Device Barcode',
      mobileBarcodeplaceholder: 'Enter Mobile Device Barcode',
      confirmMobileBarcode: 'Confirm Mobile Device Barcode',
    },
    next: 'Next',
    emergencyIdentity: 'Emergency Contact Person Identity',
    emergencyIdentityplaceholder: 'Select Emergency Contact Person Identity',
    emergencyName: 'Emergency Contact Person Name',
    emergencyNameplaceholder: 'Enter Emergency Contact Person Name',
    emergencyNumber: 'Emergency Contact Person Phone Number',
    notice: 'Notes:<br>'
            + '1. The emergency contact person is only a channel for notifying the member and cannot exercise account rights on behalf of the member.<br>'
            + '2. Notifying the emergency contact person is considered as having notified the member. Therefore, if the contact information changes, it should be updated immediately.'
            + ' If the emergency contact person refuses to answer,'
            + ' does not assist in notifying the member, or cannot be contacted, resulting in the member not receiving the notification, 88go is not responsible.'
            + ' The member is responsible for this situation.',
  },
  fogotPwd: {
    enterPhone: 'Please enter your phone number below',
    phoneNumber: 'Phone',
    phonePlaceholder: 'Enter your phone number',
    next: 'Next',
  },
  verify: {
    sendCode: 'A 4-digit verification code has been sent to',
    verify: 'Verify',
    notReceive: 'Didn\'t receive the code?',
    resendCode: 'Resend Verification Code',
    verifySuccess: 'Phone verification successful. You will be redirected to the password reset page.',
    confirm: 'Confirm',
    thxToRegister: 'Thank you for registering as an 88go member. Your phone number has been successfully verified!',
    socialMedia: 'Do you want to go to the member center to quickly log in and bind your social account?',
    loginAgain: 'Login Again',
    goFillIn: 'Có, đi đến ràng buộc',
    goHome: 'No, go to home page',
  },
  home: {
    slogan: 'Let us find a comfortable',
    idelHome: 'Ideal Home',
    saleSlogan: 'Realize',
    homeDream: 'Dream of Home',
    saleSlogan2: 'starts here',
    newHouseSlogan: 'Enjoy a new life and welcome',
    newHouseSlogan2: 'bright future!',
    keywordPlaceholder: 'Enter keywords',
    area: 'Area',
    houseType: 'Type',
    housingLayout: 'Layout',
    sort: 'Sort',
    preferredHouse: 'Preferred Houses',
    preferredConstruction: 'Preferred Construction Project',
    preferredFactory: 'Preferred Factory',
    preferredLand: 'Preferred Land',
    preferredOffice: 'Preferred Office',
    preferredShop: 'Preferred Shop',
    suitable: 'Suitable For Your House',
    suitableNewHouse: 'Suitable For Your New Project',
    suitableFactory: 'Suitable For Your Factory',
    suitableLand: 'Suitable For Your Land',
    suitableOffice: 'Suitable For Your Office',
    suitableShop: 'Suitable For Your Shop',
    more: 'View More',
    rentInPopular: 'Popular Areas for Rent',
    buyingPopular: 'Popular Areas for Purchase',
    newHousePopular: 'Popular Areas for New Project',
    factoryPopular: 'Popular Areas for Factory',
    landPopular: 'Popular Areas for Land',
    officePopular: 'Popular Areas for Office',
    shopPopular: 'Popular Areas for Shop',
    listSearch: 'List Search',
    mapSearch: 'Map Search',
    news: 'News',
    learnMoreAbout88go: 'About 88go',
    // eslint-disable-next-line max-len
    aboutDes: 'We are a platform that provides a full range of housing solutions. No matter what your housing needs are, 88go will be your reliable partner. We look forward to providing you with the best services in this housing journey and working with you to realize a new future of housing.',
    multipleListing: 'Lots of Listings',
    listingDes: 'We got numerous high-quality listings together , covering various needs and budgets.',
    noFee: 'No Agent Fees',
    noFeeDes: 'We avoid complicated agent fees to become a barrier to your housing choice.',
    convenientTransactions: 'Easy Trading',
    convenientTransactionsDes: 'Transparent purchasing process, easy and enjoyable renting and selling of  your properties.',
    whyChoose: 'Why Choose 88go',
    outPlan: 'Our Plans',
    filter: 'Filter',
    rent: 'Rent',
    sale: 'Sale Price',
    minimumPrice: 'Enter Minimum Price',
    maximumPrice: 'Enter Maximum Price',
    proportion: 'Area',
    minimumArae: 'Enter Minimum Area',
    maximumArae: 'Enter Maximum Area',
    specialRequirment: 'Special Requirements',
    search: 'Search',
    clearFilter: 'Clear',
    gender: 'Gender',
    maleOnly: 'Male Only',
    femaleOnly: 'Female Only',
    identity: 'Identity',
    choice: 'Choice',
    urgent: 'Urgent',
    searchObject: 'Don’t know where to start house hunting?',
    tellUs: 'Tell Us',
    explainYourNeed: 'Explain your needs and 88go will intelligently find the perfect object of your dreams.',
    rentHouseQuestion1: 'What is your current renting budget?',
    rentHouseQuestion1Des: 'Please enter your budget range. Your information will be kept confidential.',
    minimumBudget: 'Minimum budget',
    maximumBudget: 'Maximum budget',
    rentHouseQuestion2: 'What category of home do you currently prefer?',
    rentHouseQuestion2Des: 'Choose up to three options and your information will be kept confidential',
    prev: 'Previous',
    rentHouseQuestion3: 'What type of home do you currently prefer?',
    minRoom: 'Minimum number of rooms',
    maxRoom: 'Maximum number of rooms',
    rentHouseQuestion4: 'What layout do you currently prefer?',
    rentHouseQuestion4Des: 'Please enter your desired number of rooms and your information will be kept confidential',
    squareQuestion: 'What area do you currently prefer?',
    squareQuestionDes: 'Please enter your ideal area range, your information will be kept confidential',
    rentHouseQuestion5: 'What is your current preferred area?',
    rentHouseQuestion5es: 'Please select your preferred region. Your information will be kept confidential.',
    smartHouseSearch: 'Smart House Search',
    loginRequire: 'This function requires logging in to use',
    rentalHouseConditions: 'Your ideal rental conditions',
    reset: 'Reset',
    preferenceLayout: 'Preference layout',
    preferenceArea: 'Preference area',
    filledInOn: 'Filled In On',
    saleHouseQuestion1: 'What is your current home purchasing budget?',
    purchaseHouseConditions: 'Your ideal home purchase conditions',
    newHouseConditions: 'Your ideal new construction conditions',
    newHouseQuestion2: 'What type of case do you currently prefer?',
    factoryRentQuestion1: 'What is your current factory rental budget?',
    factorySaleQuestion1: 'What is your current factory purchase budget?',
    factoryQuestion2: 'What type of factory do you currently prefer?',
    factoryConditions: 'Your ideal factory conditions',
    landRentQuestion1: 'What is your current land rental budget?',
    landSaleQuestion1: 'What is your current land purchase budget?',
    landQuestion2: 'What type of land do you currently prefer?',
    landConditions: 'Your ideal land conditions',
    officeRentQuestion1: 'What is your current office rental budget?',
    officeSaleQuestion1: 'What is your current office purchase budget?',
    officeQuestion2: 'What category of office do you prefer?',
    officeQuestion3: 'What type of office do you prefer?',
    officeConditions: 'Your ideal office conditions',
    shopRentQuestion1: 'What is your current shop rental budget?',
    shopSaleQuestion1: 'What is your current office purchase budget?',
    shopQuestion2: 'What category of shop do you currently prefer?',
    shopQuestion3: 'What type of shop do you currently prefer?',
    shopConditions: 'Your ideal shop conditions',
    idealSlogan: 'Check out the ideal homes 88go recommends for you below!',
    rentTab: 'Rent',
    saleTab: 'Purchase',
    feature: 'This feature is only available to buyers / tenants',
    // eslint-disable-next-line max-len
    noResult: 'There are currently no properties that match your needs. You can reset your needs or refer to other houses we recommend. If a house suitable for you comes on the market, we will notify you as soon as possible.',
  },
  footer: {
    aboutUs: 'About Us',
    resource: 'Resource',
    listSearch: 'List Search',
    mapSearch: 'Map',
    whyChoose: 'Why Choose 88go',
    ourPlan: 'Our Plans',
    agentRegulations: 'Agent Regulations',
    disclaimer: 'Disclaimer',
    termsOfMembershipAndPrivacyStatement: 'Terms/Privacy',
    mortgageCalculator: 'Mortgage Calculator',
  },
  rent: {
    title: 'Rental List',
    enTitle: 'Danh sách cho thuê',
    mapSearch: 'Map',
    recommended: 'Recommended',
    forRent: 'All properties',
    forSale: 'All properties',
  },
  sale: {
    title: 'Purchase List',
    enTitle: 'Danh sách mua bán',
  },
  preSale: {
    title: 'New Project List',
    enTitle: 'Danh sách dự án mới',
    recommended: 'Recommended',
    forPreSale: 'All construction project',
    determined: 'To be determined',
  },
  factoryList: {
    title: 'Factory List',
    enTitle: 'Danh sách nhà xưởng',
    recommended: 'Recommended',
    forFactory: 'All Factories',
    factoryForRent: 'Rent',
    factoryForSale: 'Sale',
  },
  landList: {
    title: 'Land List',
    enTitle: 'Danh sách đất đai',
    recommended: 'Recommended',
    forLand: 'All Lands',
    landForRent: 'Rent',
    landForSale: 'Sale',
  },
  officeList: {
    title: 'Office List',
    enTitle: 'Danh sách văn phòng',
    recommended: 'Recommended',
    forOffice: 'All Offices',
    officeForRent: 'Rent',
    officeForSale: 'Sale',
  },
  shopList: {
    title: 'Shop List',
    enTitle: 'Danh sách cửa hàng',
    recommended: 'Recommended',
    forShop: 'All Shops',
    shopForRent: 'Rent',
    shopForSale: 'Sale',
  },
  video: {
    title: 'Videos',
    enTitle: 'Videos',
    home: 'Home',
    favorite: 'Favorite',
    latest: 'Latest',
    popular: 'Popular',
    videoType: 'Category',
    search: 'Search video title',
    views: 'Views',
    send: 'Send',
    showAllComment: 'Read more',
    showLessComment: 'Show less',
    replies: 'replies',
  },
  search: {
    sort: 'Sort',
    order: {
      1: 'Newest',
      2: 'Oldest',
      3: 'Lowest Price',
      4: 'Highest Price',
      5: 'Smallest Area',
      6: 'Largest Area',
    },
    addr: 'Location',
    city: 'Province/City',
    area: 'District',
    ward: 'Township/Neighborhood',
    type: 'Type',
    pattern: 'Layout',
    rentRange: 'Range',
    saleRange: 'Range',
    unitRange: 'Unit Price Range',
    totalRange: 'Total Price Range',
    structure: 'Structure',
    proportion: 'Area',
    houseDirection: 'Direction',
    floor: 'Floor',
    special: 'Special',
    identity: 'Identity',
    unlimited: 'Unlimited',
    // bedsit: '雅房',
    // doubleRoom: '分租套房',
    // studio: '独立套房',
    // wholeFloor: '整層住家',
    // apartment: '公寓',
    // condo: '电梯大楼',
    // house: '透天厝',
    // vila: '别墅',
    room: 'Room',
    roomUp: 'Rooms and above',
    squareMeters: 'm2',
    expand: 'Expand Options',
    collapse: 'Collapse Options',
    // eastWest: '东西向',
    // northSouth: '南北向',
    // SouthWest: '西南向',
    // northEast: '东北向',
    // northWest: '西北向',
    // southEast: '东南向',
    nearMRT: 'Near MRT Station',
    pet: 'Pets',
    furnace: 'Cooking',
    parking: 'Parking Space',
    elevator: 'Elevator',
    balcony: 'Balcony',
    shortTerm: 'Short-term Lease Available',
    hostPublished: 'Owner Published',
    maleOnly: 'Male Only',
    femaleOnly: 'Female Only',
    officeWorker: 'Office Worker',
    family: 'Family',
    student: 'Student',
    native: 'Local Resident',
    foreigners: 'Foreigner',
    includingParking: 'Includes Parking',
    starSchool: 'Star School District',
    layout: 'Layout Diagram',
    above: 'and above',
    caseType: 'Case',
  },
  map: {
    RentalMapSearch: 'Rental Map Search',
    RentalMapSearchEn: 'Tìm kiếm bản đồ cho thuê',
    SaleMapSearch: 'Sale Map Search',
    SaleMapSearchEn: 'Tìm kiếm bản đồ mua bán',
    preSaleMapSearch: 'New Project Map Search',
    prsSaleMapSearchEn: 'Tìm kiếm bản đồ trước khi bán hàng',
    factoryMapSearch: 'Factory Map Search',
    factoryMapSearchEn: 'Tìm kiếm bản đồ nhà xưởng',
    landMapSearch: 'Land Map Search',
    landMapSearchEn: 'Tìm kiếm bản đồ đất đai',
    officeMapSearch: 'Office Map Search',
    officeMapSearchEn: 'Tìm kiếm bản đồ văn phòng',
    shopMapSearch: 'Shop Map Search',
    shopMapSearchEn: 'Tìm kiếm bản đồ cửa hàng',
    listSearch: 'List Search',
    county: 'County',
    district: 'District',
    type: 'Type',
    minimumPrice: 'Enter Minimum Rental Price',
    maximumPrice: 'Enter Maximum Rental Price',
    minimumNewProjectPrice: 'Please enter the minimum unit price',
    maximumNewProjectPrice: 'Please enter the maximum price',
    minimumsSquare: 'Enter Minimum Square Meters',
    maximumsSquare: 'Enter Maximum Square Meters',
    selectTime: 'Select Time',
  },
  detail: {
    none: 'None',
    can: 'Allowed',
    not: 'Not Allowed',
    month: 'Month',
    months: 'Months',
    tenThousand: 'Ten Thousand',
    moveAnytime: ', move in anytime',
    structure: 'Structure',
    room: 'Bedroom',
    hall: 'Living Room',
    bathroom: 'Bathroom',
    balcony: 'Balcony',
    proportion: 'Area',
    squareMeter: 'm2',
    floor: 'Floor',
    houseDirection: 'Direction',
    aroundTheLocation: 'Surroundings',
    location: 'Location',
    surroundings: 'Surrounding',
    traffic: 'Traffic',
    education: 'Education',
    life: 'Life',
    medical: 'Medical',
    live: 'Land/Living',
    equipmentAndRules: 'Equipments and Rules',
    equipment: 'Provided Equipments',
    homeEquipment: 'Equipments',
    washingMachine: 'Washing Machine',
    refrigerator: 'Refrigerator',
    network: 'Internet',
    television: 'Television',
    payTelevision: 'Cable TV',
    waterHeater: 'Water Heater',
    airConditioner: 'Air Conditioner',
    gas: 'Natural Gas',
    microWave: 'Microwave',
    bed: 'Bed',
    closet: 'Wardrobe',
    sofa: 'Sofa',
    table: 'Table and Chairs',
    parking: 'Parking Space',
    elevator: 'Elevator',
    fireSecurity: 'Fire Safety',
    rentalRules: 'Rental Rules',
    pet: 'Pets Allowed',
    furnace: 'Cooking Allowed',
    tax: 'Tax',
    householdRegistration: 'Household Registration',
    godTable: 'Ancestral Tablet',
    identity: 'Identity Requirements',
    housingDetails: 'Housing Details',
    factoryDetails: 'Factory Details',
    landDetails: 'Land Details',
    officeDetails: 'Office Details',
    shopDetails: 'Shop Details',
    rentalInstructions: 'Rental Instructions',
    factoryRentalInstructions: 'Rental Instructions',
    rentIncludes: 'Rent Includes',
    deposit: 'Deposit',
    managementFees: 'Management Fees',
    minimumRentalPeriod: 'Minimum Rental Period',
    houseDescription: 'House Description',
    factoryInfo: 'Factory Information',
    landInfo: 'Land Information',
    salesDescription: 'Sales Description',
    descriptionOfHousePropertyRegistration: 'Description of Property Registration',
    houseQA: 'House FAQs',
    factoryQA: 'Factory FAQS',
    landQA: 'Land FAQS',
    officeQA: 'Office FAQS',
    shopQA: 'Shop FAQS',
    houseQADescription: 'Enter your question, the poster will respond to you as soon as possible',
    contentPLaceholder: 'Enter your question here',
    property: 'Property',
    statutoryPurpose: 'Statutory Purpose',
    buildingArea: 'Building Area',
    similarHouses: 'Similar Houses',
    nearbyFactories: 'Nearby Factories',
    nearbyLand: 'Nearby Lands',
    landlordPage: 'View',
    sendQuestion: 'Send Question',
    postedBy: 'Published by',
    publishedIn: 'Published in',
    viewAll: 'View all',
    houseAge: 'House age',
    parkingSpace: 'Parking space',
    report: 'Report',
    share: 'Share',
    shareToFriends: 'Share your good house with more friends!',
    copyLink: 'Copy link',
    shareOnFb: 'Share on facebook',
    shareOnZalo: 'Share on Zalo',
    shareother: 'Other Method',
    notice: '88GO reminds you: It is no longer convenient for the poster to view the property. It is a scam to ask for a deposit on the grounds that the property is in high demand!',
    nearbyQuotations: 'Nearby Quotations',
    investment: 'Investment',
    finished: 'Finished',
    handover: 'Handover',
    houseCondition: 'House Condition',
    openSale: 'Open Sale',
    loanRatio: 'Loan Ratio',
    receptionCenter: 'Reception Center',
    layout: 'Layouts',
    salesInfo: 'Sales Information',
    community: 'Community',
    building: 'buildings',
    buildingPlanning: 'Building Planning',
    aboveGround: 'above ground',
    underGround: 'under ground',
    buildingMaterials: 'Building Materials',
    builderInfo: 'Builder information',
    constructionCompany: 'Construction company',
    architecturalDesign: 'Architectural design',
    sellingByYourself: 'Selling by yourself',
    features: 'Features',
    comparePrices: 'Compare prices',
    service: 'Service',
    legalDocument: 'Legal document',
    notUploadedYet: 'Not Uploaded Yet',
    latestNews: 'Latest News',
    viewsAll: 'View All',
    newProjectStory: 'Project Story',
    appQrcode: 'Scan the QR Code using the app',
    clickLink: 'Click',
    saleBySplits: 'Sale By Splits',
    widthOfTheFrontRoad: 'Width from the road',
    buildingHeightLimit: 'Building height limit',
    specification: 'Specification',
    passengerElevator: 'Passenger elevator',
    freightElevator: 'Freight elevator',
    includedInRent: 'Included in rent',
    nearbyOffice: 'Nearby offices',
    nearbyShop: 'Nearby stores',
    averageRental: 'average rental price nearby',
    averageSale: 'average selling price nearby',
    averageAmount: 'Average price',
  },
  publisher: {
    rentList: 'Available Rental Properties',
    saleList: 'Available Sale Properties',
    more: 'View More',
    noHouseForRent: '- Currently, there are no houses available for rent -',
    noHouseForSale: '- Currently, there are no houses available for sale -',
    openRating: 'View Ratings',
    currently: 'Total of',
    house: 'houses',
    case: 'cases',
    factory: 'factories',
    land: 'lands',
    newHouse: 'new items added today',
    items: '',
    tabs: {
      home: 'Home',
      rent: 'Rent',
      sale: 'Sale',
      detail: 'Detail',
      message: 'Consultation',
    },
    working: 'working',
    views: 'views',
    companyInfo: 'Company information',
    company: 'company',
    workingYears: 'year',
    workingExperience: 'experience',
    qaDescription: 'Enter the question content and the operator will reply to you as soon as possible',
    onlyTenItems: 'Only show the latest 10 items',
    evaluationDetails: 'Evaluation details',
    summary: 'Summary',
    evaluate: 'Evaluate',
    comment: 'Comment',
    rating: 'Rating',
    explanation: 'Explanation',
  },
  info: {
    learnMore: 'Learn More',
    outPlan: 'Our Membership Plans',
    outPlanEn: 'Gói dịch vụ của chúng tôi',
    agencyRegulations: 'Agent Regulations',
    agencyRegulationsEn: 'Agent',
    choose88go: 'Why Choose 88go',
    choose88goEn: 'Vì sao chọn 88go',
    disclaimer: 'Disclaimer',
    disclaimerEn: 'Tuyên bố miễn trừ trách nhiệm',
    policy: 'Membership Rules & Privacy Policy',
    policyEn: 'Điều khoản và quyền riêng tư',
  },
  about: {
    slogan1: 'Renting and Buying, Perfect Harmony!',
    slogan2: 'Embrace the Future of Living,',
    welcome: 'Welcome to 88go Real Estate Platform!',
    description1: 'We are a platform offering comprehensive housing solutions.'
            + ' Whatever your residential needs, 88go will be your reliable partner.'
            + ' We look forward to providing you with the best quality service on this housing journey and working with you to realize a new future in living.',
    slogan3: 'Diverse Listings, More Choices',
    description2: '88go brings together numerous high-quality listings, covering various needs and budgets.'
            + ' From cozy apartments to spacious villas, from downtown cores to serene suburbs, our listings are diverse and are sure to meet your requirements.'
            + ' We also provide multiple devices for browsing, allowing you to find your ideal home anytime, anywhere.',
    download: 'Download the App',
    slogan4: 'No Agent Fees, Save Your Expenses',
    description3: 'We believe renting or buying a house should be easy and enjoyable, not burdened by hefty costs.'
            + ' 88go promises not to let complicated agent fees be a barrier to your housing choice.'
            + ' With just a small membership fee, you can enjoy secure real estate transactions and maximize your benefits from the deals.',
    ourPlan: 'Explore Our Plans',
    slogan5: 'Worry-Free Renting, Partners Taking Care of You!',
    description4: 'At 88go, we are dedicated to creating a secure and smooth transaction experience for you, enabling you to confidently choose your ideal residence.'
            + ' Our partnered professionals, with their rich experience and expertise, assist you at every step of the housing transaction.'
            + ' Whether it\'s legal support, transaction protection, or handling details, they go the extra mile to ensure your interests are maximally protected.',
    slogan6: 'Let us make the deal on your home',
    slogan7: 'Add confidence and convenience',
    getStared: 'Get started today and find the right person and your home!',
    description5: 'At 88go, we provide you with a platform to start searching for your ideal home as well as good housing offers.'
            + ' Whether you are a tenant or a landlord, we will help you find the right home and the right deal. Let\'s start this exciting journey together!',
    anyQuestion: 'If you have any questions, feel free to contact us',
    contactUs: 'Contact Us',
  },
  plan: {
    slogan1: 'Connect Your Dream Home, Find Your Perfect Place',
    description1: 'Through professional listing plans, your property will enjoy greater exposure and superior display effects.'
            + ' Joining our membership provides you with more complete and secure housing and landlord information, and our agent plans allow you to get more benefits in transactions.',
    slogan2: 'Let Your House Shine with Unique Brilliance',
    description2: 'The simplest, fastest listing plans!',
    rent: 'Rent',
    sale: 'Sale',
    advertise: 'Advertisement',
    viewImageAd: 'Image AD Placement Instructions',
    whatIsAd: 'What Is An Image Ad?',
    // eslint-disable-next-line max-len
    whatIsAdDes: 'It is a form of advertising that uses images to attract users. When users click, it will lead to your website or a specially designed landing page, or a page such as a house listing published on the website 88gohouse.com.',
    benefitsOfBanner: 'Benefits Of Banner Ads',
    benefitsOfBannerDes1: '1.Brand promotion',
    benefitsOfBannerDes2: '2.Increase traffic to your website',
    benefitsOfBannerDes3: '3.Increase potential customers and increase sales revenue',
    imageLocation: 'Image Location And Size Description',
    size: 'Size (pixels)',
    bannerAdWeb: 'Home Page Carousel Ads On Web',
    bannerAdMobile: 'Home Page Carousel Ads On Mobile',
    sidebarAd: 'Home Page Sidebar Ad On Web',
    notice: 'Notice',
    // eslint-disable-next-line max-len
    noticeDes1: '1.According to the screen configuration, it is recommended that the main images and text should be placed in the green safe area of the diagram below for the carousel image on the home page of the web version.',
    noticeDes2: '2.Depending on the screen type, some parts may still be blocked',
    floatAd: 'Floating Ads On Web',
    otherNotice: 'Other Notices',
    otherNoticeDes1: '1. Single picture advertising plan only uploads one picture advertisement in the specified location',
    otherNoticeDes2: '2. The release time of an image advertisement is calculated as 30 days.',
    otherNoticeDes3: '3. After the image advertisement application is successfully submitted, the review time is about 1-3 working days',
    singleRentListing: 'Single Rental Listing Plan',
    singleSaleListing: 'Single Sale Listing Plan',
    rentListingDescription: 'Suitable for homeowners/agents with minimal listing needs',
    basicPlan: 'Basic Plan',
    basicPlanDescription: 'Suitable for landlords or sellers who want to make simple listings',
    basicPlanDetail: {
      limit: 'Plan duration is',
      price: 'RMB per two months',
      publish: 'Can publish',
      items: 'listings',
      dollar: 'dollar',
      point: 'points',
      day: 'days',
      month: 'months',
      hours: 'Up to 72 hours to change property address',
      times: 'times',
      publishTime: 'Listing period is',
      noExposure: 'No exposure plan',
      exposure: 'Exposure method',
    },
    freePublishing: 'Published by secretary for free',
    singleListingNotice: '※ Plan Validity Period: Valid within',
    singledaysofpurchase: 'days from the date of purchase.',
    basicPlanNotice: '※ Plan Validity Period: Valid within',
    basicdaysofpurchase: 'days from the date of purchase. Items in the plan must be used within the validity period, and will be void after expiration.',
    buyPlan: 'Purchase This Plan',
    bestDeal: 'Best Deal',
    proPlan: 'Professional Plan',
    proPlanDescription: 'For landlords or sellers who want their properties to stand out more prominently.',
    vipPlan: 'VIP Plan',
    vipPlanDescription: 'Suitable for landlords or sellers who want their properties to be highlighted to the maximum extent.',
    slogan3: 'Quick, Simple, and Effective Real Estate Management',
    description3: 'Listing plans suitable for multiple properties with transaction needs! ',
    membershipPlan: 'Membership Plan',
    membershipPlanDescription: 'Suitable for members looking to buy or rent properties.',
    membershipPlanDetail: {
      landloardInfo: 'Access to landlord contact information',
      rateToLandloard: 'Ability to rate landlords',
      limit: 'Membership period is',
    },
    slogan4: 'Stand Out, Let Your Property Shine',
    description4: 'Value-added services, providing more opportunities for exposure and visibility.',
    service: {
      updateSort: 'Update Sort',
      manualUpdates: 'Manual',
      manualUpdatesDescription: '(Manually click to update the sort order)',
      automaticUpdates: 'Automatic',
      automaticUpdatesDescription: '(Automatically updates the sort order)',
      regularUpdates: 'Regular',
      regularUpdatesDescription: '(Customize update frequency and time)',
      adService: 'Advertising Services',
      prioritization: 'Priority Sorting',
      prioritizationDescription: '(Always be placed before regular listings)',
      preferred: 'Preferred',
      preferredDescription: '(Preferred section)',
      recommended: 'Recommended',
      recommendedDescription: '(Recommended section)',
      labelingService: 'Labeling Services',
      pin: 'Pin',
      pinDescription: '(Prominent pin icon to indicate priority)',
      choice: 'Featured',
      choiceDescription: '(Prominent featured icon)',
      expedtedTag: 'Urgent Tag',
      expedtedTagDescription: '(Prominent urgent tag)',
      publishByDedicated: 'Published by secretary',
      publishByDedicatedDescription: '(Published by secretary)',
    },
    slogan5: 'Make Your Property Transaction Journey More Comfortable',
    description5: 'Basic plans for everyone to use!',
    removeAdPlan: 'Ad-Free Plan',
    removeAdPlanDescription: 'Suitable for members who do not want to be disturbed by ads',
    removeAllBanner: 'Remove all ads from the website',
    removeAgentPlan: 'Agent-Free Plan',
    removeAgentPlanDescription: 'Suitable for members who do not want agent interference',
    removeAgentBanner: 'Can remove all agency listings from the website',
    qa: 'Frequently Asked Questions',
    qaDescription: 'If you have any questions about our plans, please feel free to contact us',
    qaContactUs: 'Contact Us',
    subErr: 'Sub-account status cannot purchase plans. If you need to purchase, please switch to the agency account.',
  },
  agent: {
    slogan1: 'Three-Generation Profit, Creating Residential Income Together!',
    subSlogan: 'Invite others to join; successful transactions will bring you benefits.',
    description1: 'In order to ensure that every member enjoys more returns, 88go has specially introduced our unique three-generation profit-sharing program.'
            + ' When you successfully invite others to join as members, you will also receive a share of their transaction profits.'
            + ' What\'s even more exciting is that you can also share the agent\'s profit from their referrals,'
            + ' not limited to your transactions, but also including the members you referred and those they referred.',
    process: 'Agent Process',
    join: 'Before Joining',
    joinDescription2: 'Before joining 88go, you can become part of someone else\'s agents by entering their code. Once you become our member, you also have the opportunity to become an agent.',
    becomeMember: 'Become a Member',
    becomeMemberDescription: 'After becoming a member, you will receive a unique agent code. You can share this code with friends and family around you, inviting them to join 88go.',
    becomeAgent: 'Become an Agent',
    becomeAgentDescription: 'Once you successfully invite others to join, you will have the status of an agent.'
            + ' Through the agent program, you will receive commission income, and you can also share the transaction profits of the people you referred.',
    bonus: 'How Commission is Calculated',
    benefits: 'Benefits of Three-Generation Profit Sharing',
    invite: 'Invite Multiple People',
    inviteDescription: 'The chain effect allows your influence and earnings to continuously expand. The more people you invite, the more commission you will receive.',
    accounting: 'Transparent Accounting',
    accountingDescription: 'Income statistics from the 1st to the 30th of each month will be remitted in the following month.'
            + ' Members can also view performance rewards in real-time, ensuring that your rewards are timely and transparent.',
    profits: 'Multi-Generation Profits',
    profitsDescription: '1 per month - Even if the members you refer refer, you can share in their trading interests.',
    joinHands: 'Join Hands to Share the Glory of Living!',
    joinHandsDescription: 'This chain effect allows your influence and earnings to continue to expand,'
            + ' making you an important part of a community that wins together in living.'
            + ' 88go believes that this mutually beneficial model can bring more value to each member and together we can create a better future for renting and buying.',
    join88go: 'Join the Ranks of 88go',
  },
  disclaimer: {
    slogan: 'Thank you for using 88go Property Trading Platform.',
    description1: 'Welcome to 88go Property Trading Platform (hereinafter referred to as "we" or "the platform").',
    description2: 'Please read the following disclaimer carefully before using our services. Your use of our services signifies your agreement to these terms.',
    list: {
      title1: '1. Accuracy of Information',
      description1: 'We make every effort to ensure the accuracy and completeness of property information and content on the platform. However,'
                + ' we cannot guarantee the accuracy and completeness of all information.'
                + ' You should independently assess and verify the correctness of property information.',
      title2: '2. Rental and Real Estate Transactions',
      description2: '2.1 We provide only an information platform for rental and real estate transactions and are not involved in the actual transactions.'
                + ' Your rental or real estate transactions on the platform are your own decision, and we are not responsible for the performance or outcome of the transactions.',
      description3: '2.2 We recommend conducting thorough research and due diligence before entering into any transaction to ensure its legality and feasibility.',
      title3: '3. Third-Party Content and Services',
      description4: 'The platform may contain content and links from third parties.'
                + ' These contents and services are unrelated to us, and we are not responsible for their accuracy, legality, or security.'
                + ' If you use these contents and services, you do so at your own risk.',
      title4: '4. Use at Your Own Risk',
      description5: 'Your use of our services is at your own risk.'
                + ' We do not guarantee that the platform will be error-free, secure, or uninterrupted.'
                + ' We are not responsible for any content that may damage your computer system.',
      title5: '5. Limitation of Legal Liability',
      description6: 'To the maximum extent permitted by applicable law,'
                + ' we shall not be liable for any losses or damages arising from your use of our services, whether direct, indirect, incidental, special, or consequential.',
      title6: '6. Changes to Disclaimer',
      description7: 'We reserve the right to update the disclaimer at any time. Please check regularly to ensure you are aware of the latest content.',
      description8: 'If you have any questions about the disclaimer, please contact us.',
    },
    notice: 'This disclaimer is effective from [Effective Date]. <br>If you have any questions or concerns about our terms, please contact us.',
    contactUs: 'Contact Us',
  },
  policy: {
    slogan: 'Thank you for using 88go Property Trading Platform.',
    subTitle1: '88go Property Trading Platform Membership Rules',
    subTitle2: 'Privacy Policy',
    description1: 'Welcome to 88go Property Trading Platform (hereinafter referred to as "we" or "the platform").'
            + ' Please read the following membership rules carefully before using our services. Registering as our member signifies your agreement to comply with these rules and statements.',
    description2: 'We highly value your privacy and are committed to protecting your personal information. Our Privacy Policy outlines in detail how we collect, use, and protect your information.',
    list: {
      title1: '1. Account Usage',
      description1: '1.1 The personal information you provide during registration must be true and accurate.'
                + ' Your account is for your personal use only and cannot be transferred, shared, or rented to third parties.',
      description2: '1.2 You are responsible for protecting your account and password, and you are responsible for all activities that occur under your account.'
                + ' If you suspect unauthorized use of your account, you must notify us immediately.',
      title2: '2. Landlord Qualifications and Agency Activities',
      description3: '2.1 Our platform aims to provide rental and property buying services and prohibits agency activities on our platform.',
      description4: '2.2 If we find that you are an agent, your account will be immediately deleted, and the plan you originally purchased will not be refunded.',
      title3: '3. Membership Fees and Payments',
      description5: '3.1 You can choose to purchase different plans we offer to access specific services and features. Payment methods and fees will depend on the plan you choose.',
      description6: '3.2 All payments will be made securely, and we will protect your payment information.'
                + ' After payment is completed, you will immediately enjoy the corresponding services based on the selected plan.',
      title4: '4. Member Code of Conduct',
      description7: '4.1 You must comply with all applicable laws and regulations and maintain politeness and respect while using our platform.',
      description8: '4.2 You are not allowed to publish false information, content that infringes on the rights of others, or engage in illegal activities on the platform.',
      title5: '5. Limitation of Legal Liability',
      title6: '6. Changes to the Disclaimer',
    },
    privacy: {
      title1: '1. Collection of Information',
      description1: '1.1 We may collect personal information that you provide when registering, using our services, participating in activities, or communicating with us.',
      description2: '1.2 We may automatically collect certain usage data, such as access logs, IP addresses, device information, to improve our services.',
      title2: '2. Use of Information',
      description3: '2.1 We use your information to provide, maintain, and enhance our services and to communicate with you.',
      description4: '2.2 We will not share your personal information with unauthorized third parties.',
      title3: '3. Information Protection',
      description5: 'We take reasonable security measures to protect your information from unauthorized access, use, or disclosure.',
      title4: '4. Information Deletion',
      // eslint-disable-next-line max-len
      description6: "{'Access to Your Personal Information, You are entitled to view, amend, or delete the personal information that we hold. Email your request to our data protection officer [YOUR PHONE NO.] at 88gohouse@gmail.com and we will work with you to remove any of your personal data we may have.'}",
    },
    notice: 'These membership terms and privacy policy are effective from [effective date], and we reserve the right to update them at any time.'
            + ' Please check these terms regularly to ensure you are aware of the latest information.'
            + ' If you have any questions or concerns about our membership terms or privacy policy, please contact us.',
  },
  friendRules: {
    title: 'Friend Recommendation Rules',
    enTitle: 'Quy tắc đề xuất bạn bè',
    slogan1: 'Recommend by friends, share and publish rewards!',
    subSlogan: 'Invite others to join and enjoy publishing rewards together',
    // eslint-disable-next-line max-len
    description1: 'In order to allow every member to enjoy more rewards, 88go has launched our unique member-friend recommendation program.When you successfully invite others to join as a member, you will receive rewards when they list. What\'s more worth looking forward to is that you can get listing rewards not only for your friends, but also for the members you recommend and the members they recommend.',
    process: 'Friend recommendation process',
    join: 'Before Joining',
    joinDescription2: 'Before joining 88go, you can become part of someone else\'s agents by entering their code. Once you become our member, you also have the opportunity to become an agent.',
    becomeMember: 'Become a Member',
    becomeMemberDescription: 'After becoming a member, you will receive a unique agent code. You can share this code with friends and family around you, inviting them to join 88go.',
    becomeAgent: 'Become an Agent',
    becomeAgentDescription: 'Once you successfully invite others to join, you will have the status of an agent.'
        + ' Through the agent program, you will receive commission income, and you can also share the transaction profits of the people you referred.',
    bonus: 'How to calculate bonus',
    rewardCalculationRules: 'Reward calculation rules',
    algorithm: 'Number of items published at each level × single item reward × total number of people published at each level',
    listPara1: 'Members will receive a reward of 10,000 points for a single publication.',
    level1: 'First level friend publishing reward',
    level2: 'Secind level friend publishing reward',
    level3: 'Third level friend publishing reward',
    // eslint-disable-next-line max-len
    listPara2: 'The first level of friends can reward members with 1,000 points for each publication. The maximum number of people who can list items is 5, and there is no upper limit on the number of items that can be listed.',
    // eslint-disable-next-line max-len
    listPara3: 'The second level friends can reward members with 500 points for each publication. The maximum number of people who can list items is 5, but there is no upper limit on the number of items that can be listed.',
    // eslint-disable-next-line max-len
    listPara4: 'The third level friends can reward members with 250 points for each publication. The maximum number of people who can list items is 5, but there is no upper limit on the number of items that can be listed.',
    forExample: 'For example:',
    invite: 'Invite Multiple People',
    inviteDescription: 'The chain effect allows your influence and earnings to continuously expand. The more people you invite, the more commission you will receive.',
    accounting: 'The amount is transparent',
    // eslint-disable-next-line max-len
    accountingDescription: 'The reward statistics at the end of each month will be automatically distributed, and members can also check the rewards immediately to ensure that the reward distribution calculation is instant and transparent.'
        + ' Members can also view performance rewards in real-time, ensuring that your rewards are timely and transparent.',
    profits: 'Generation-skipping rewards',
    profitsDescription: 'Even if you are a member recommended by a member you introduced, you can also get corresponding listing rewards.',
    joinHands: 'Join Hands to Share the Glory of Living!',
    joinHandsDescription: 'This chain effect allows your influence and earnings to continue to expand,'
        + ' making you an important part of a community that wins together in living.'
        + ' 88go believes that this mutually beneficial model can bring more value to each member and together we can create a better future for renting and buying.',
    join88go: 'Join the Ranks of 88go',
    me: 'Me',
    level: 'Level',
    totalNumberOfPublishing: 'Total number of publications',
    singleReward: 'Single reward',
    numberOfPublishers: 'Number of publishers',
    rewardCalculation: 'Reward Calculation',
    totalRewardThisMonth: 'Total reward in this month',
    individual: 'Individual',
    level1Step: 'First level',
    level2Step: 'Second level',
    level3Step: 'Third level',
  },
  upgrade_account: {
    title1: 'Want to take your business to the next level？',
    // eslint-disable-next-line max-len
    description1: 'Now, with our group account service, you can take your business to new heights!  From now on, you can not only operate a single shop, but also expand to multiple branches, easily manage the performance of each branch, and view detailed reports, everything is under your control!',
    learnMore: 'Learn More',
    subTitle1: 'After upgrading your account, you can',
    title2: 'Set up multiple branch accounts for easy management',
    // eslint-disable-next-line max-len
    description2: 'You can easily link your branch account with your group account, check the business status of each branch, and effectively manage your branch\'s customer list and their needs.',
    subTitle2: 'Can also',
    title3: 'Set performance goals and confirm them instantly',
    // eslint-disable-next-line max-len
    description3: 'Branches can set their own business goals and assign tasks, and calculate the goal achievement rate based on returns.  Through the account, the group can check the trends of each branch anytime and anywhere, and clearly understand the operating status and direction of each shop.',
    subTitle3: 'Can still have',
    title4: 'Detailed reports to easily analyze business status',
    // eslint-disable-next-line max-len
    description4: 'Whether it\'s each branch\'s performance report, monthly target summary or customer demand table, everything is at your fingertips. You can instantly grasp the performance goals and actual results of each branch, helping you better formulate overall sales strategies and management. policy.',
    title5: 'Upgrade now and experience endless possibilities!',
    // eslint-disable-next-line max-len
    description5: 'No longer just run a single shop, join our group account service now, try it for free for one month, and start a new chapter in your business!',
    monthlyFee: 'Monthly',
    yearFee: 'Year',
    title6: 'Discover the benefits of a group account - try it for free for one month!',
    // eslint-disable-next-line max-len
    description6: 'Starting now, we\'re excited to offer you a one-month free trial to explore all the features and benefits of our Group Account!  No risks, no limits, just your chance to explore and grow your business!',
    notice: '※ If there is no upgrade after the trial, will be automatically turned off.',
    subscribe: 'Subscribe',
    notice2: '※ It is valid for 30 days from the subscription date. If no action is taken to cancel or change the plan upon expiration, the same plan will be automatically renewed.',
  },
  mortgageCalculator: {
    enTitle: 'Công cụ tính khoản vay',
    loanAmount: 'Loan amount',
    loanTerm: 'Loan term',
    loanRate: 'Loan rate',
    loanType: 'Loan type',
    average: 'Average monthly payment',
    paymentInEqual: 'Payment in equal installments, interest calculated on decreasing balance',
    chooseBankRate: 'Bank',
    rate: 'Rate',
    monthlyInterest: 'Monthly interest',
    interestPayable: 'Interest payable',
    totalAmountPayable: 'Total payable',
    expand: 'Expand',
    collapse: 'Collapse',
    detail: 'Details',
    period: 'Period',
    balancePeriod: 'Balance per period',
    principalPayable: 'Principal payable',
    principalAndInterest: 'Principal + interest',
    total: 'Total',
    description: 'Description',
    // eslint-disable-next-line max-len
    para1: 'The loan calculation function is a function provided by Vietnam Commercial Bank to assist customers in estimating the interest and principal amount required to pay for a loan.',
    para2: 'To use the loan calculation feature, customers need to provide some basic information about their loan, including:',
    loan: 'Loan',
    para3: 'Based on this information, the function will calculate and display the following information to the customer:',
    list1: 'Monthly principal payment',
    list2: 'Monthly interest payment',
    list3: 'Total amount to be paid for the entire loan',
    // eslint-disable-next-line max-len
    para4: 'The Vietnam Bank Loan Calculator function is a useful tool that helps customers estimate the cost of a loan before deciding to borrow. This feature also helps customers compare interest rates and loan terms between banks to choose the loan that best suits their needs.',
    para5: 'How to use Vietnam Bank loan calculation function',
    para6: 'To use the Vietnam Bank loan calculation function, customers can follow the steps below:',
    list4: 'Visit the website or mobile banking app of the bank where you want to borrow money.',
    list5: 'Look for the loan calculator feature.',
    list6: 'Enter the necessary information about your loan.',
    list7: 'Press the "Calculate" button.',
    para7: 'Message to be entered',
    list8: 'Loan amount: The amount of money you want to borrow from the bank.',
    // eslint-disable-next-line max-len
    list9: 'Loan interest rate: The interest rate you will pay for a loan. Loan rates are usually calculated annually, but you can enter a monthly loan rate if the feature allows.',
    list10: 'Loan term: How long you have to repay the loan. Loan terms are usually calculated in years, but if the feature allows, you can enter the loan term in months.',
    para8: 'Displayed message',
    list11: 'Monthly Principal Payment: The amount of principal you need to pay each month to gradually reduce your loan.',
    list12: 'Monthly Interest Payment: The amount of interest you pay each month on your loan.',
    list13: 'Total Loan Payment: The total amount of principal and interest you need to pay for the entire loan.',
    para9: 'Notes',
    // eslint-disable-next-line max-len
    list14: 'The information displayed by the Vietnam Bank loan calculation function is for reference only. Actual interest and principal amounts may vary based on other factors, such as inflation and market interest rates.',
    list15: 'You should consult a banking expert for detailed advice on your loan.',
  },
  files: {
    download: 'Download File',
    downloadEn: 'Tập tin tải về',
    updateAt: 'Updated at:',
  },
  publish: {
    description: 'Choose the appropriate type of listing for your needs.',
    checkType: 'Confirm Type',
    information: 'Fill in',
    confirmPublish: 'Confirm Plan',
    complete: 'Complete',
    thisAccount: 'Account',
    specifyAccount: 'Specified account',
    rental: 'House for rent',
    sale: 'House for sale',
    factoryRental: 'Factory for rent',
    factorySale: 'Factory for sale',
    landRental: 'Land for rent',
    landSale: 'Land for sale',
    officeRental: 'Office for rent',
    officeSale: 'Office for sale',
    storeRental: 'Store for rent',
    storeSale: 'Store for sale',
    plan: 'Plan',
    single: 'Single',
    goToListing: 'Publish',
    largeNumberOfItems: 'Is there a need for a large number of items to be published?',
    importObjectInBatches: 'Import objects in batches',
    form: {
      housingCategory: 'Category',
      housingCypePlaceholder: 'Select Type',
      housingType: 'Type',
      factoryType: 'Type',
      landType: 'Type',
      housingTypePlaceholder: 'Select Type',
      title: 'Title(Maximum word: 99 characters)',
      titlePlaceholder: 'Enter Title',
      titleDesPlaceholder: 'Project Name',
      rentalInformation: 'Rental Information',
      saleInformation: 'Sale Information',
      price: 'Price per Unit',
      totalPrice: 'Total Price',
      squareMeters: 'm2',
      household: 'household',
      priceIncludingParking: 'Including Parking Price',
      priceNotIncludingParking: 'Excluding Parking Price',
      includingParking: 'Including Parking',
      noIncludingParking: 'Excluding Parking',
      parkingSpacePrice: 'Parking Space Price',
      parkingArea: 'Parking Area (m2)',
      parkingAreaPlaceholder: 'Select Parking Type',
      publicFacilities: 'Public Facilities',
      toBeConfirmed: 'To Be Confirmed',
      rent: 'Rent',
      dollarAndMonth: 'VND / Month',
      deposit: 'Deposit',
      choosePlaceholder: 'Select',
      withLease: 'Currently for rent',
      minimumLease: 'Minimum period',
      rentalPeriod: 'Rental period',
      canMoveIn: 'Available for Move-In',
      managementFees: 'Management Fees',
      enterPlaceholder: 'Enter',
      rentIncludes: 'Rent Includes',
      all: 'All',
      waterBill: 'Water Bill',
      electricityBill: 'Electricity Bill',
      payCable: 'Cable TV',
      network: 'Internet',
      gasFee: 'Gas Fee',
      manFee: 'Management Fee',
      housingInformation: 'Housing Information',
      factoryInformation: 'Factory Information',
      landInformation: 'Land Information',
      officeInformation: 'Office Information',
      shopInformation: 'Shop Information',
      houseAddress: 'Address',
      factoryAddress: 'Address',
      landAddress: 'Address',
      officeAddress: 'Address',
      shopAddress: 'Address',
      hideDoorNumber: 'Hide',
      county: 'Province/City',
      district: 'City/County/District',
      road: 'Road/Street',
      addr: 'Address',
      lane: 'Lane',
      alley: 'Alley',
      number: 'Number',
      of: 'of',
      rentalFloor: 'Rental Floor',
      saleFloor: 'Sale Floor',
      totalFloor: 'Total Floors',
      communityName: 'Community Name',
      communityNamePlaceholder: 'Enter Community Name',
      legalPurpose: 'Legal Purpose',
      legalPurposePlaceholder: 'Select Legal Purpose',
      orientation: 'Orientation',
      orientationPlaceholder: 'Select Orientation',
      decorationLevel: 'Decoration Level',
      decorationLevelPlaceholder: 'Select Decoration Level',
      structure: 'Layout',
      structureDescription: '(Please fill in accurately. Disputes arising from inaccurate information will not be our responsibility.)',
      layout: 'Open Layout',
      rooms: 'Rooms',
      hall: 'Living Room',
      bathrooms: 'Bathrooms',
      balcony: 'Balcony',
      area: 'Area (Square Meters)',
      houseAge: 'Age of House (Years)',
      houseAgeUnknown: 'Age Unknown',
      areaDescription: '(Please fill in accurately. Disputes arising from inaccurate information will not be our responsibility.)',
      lifeFunction: 'Land/Living',
      lifeFunctionDescription: 'Overview of Living',
      nearSchool: 'Near School',
      nearPark: 'Near Park',
      nearMarket: 'Near Market',
      nearStore: 'Near Store',
      nearHospital: 'Near Hospital',
      nearNightMarket: 'Near Night Market',
      nearConvenience: 'Near Convenience Store',
      nearMRT: 'Near MRT',
      starSchool: 'Star School District',
      featureDescription: 'Enter Feature Description',
      // eslint-disable-next-line max-len
      lifePlaceholder: 'For example: 1. Land information area, orientation, red book, etc. Address, location, total transfer price.\n2. Surrounding convenience facilities such as food markets, schools, community offices, shopping malls, etc.',
      provideEquipment: 'Provided Equipment',
      homeEquipment: 'Home Equipment',
      washingMachine: 'Washing Machine',
      refrigerator: 'Refrigerator',
      television: 'Television',
      payTelevision: 'Pay TV',
      waterHeater: 'Water Heater',
      airConditioner: 'Air Conditioner',
      gas: 'Gas',
      microWave: 'Microwave',
      bed: 'Bed',
      closet: 'Closet',
      sofa: 'Sofa',
      table: 'Table and Chairs',
      parking: 'Parking Space',
      elevator: 'Elevator',
      fireAndSecurity: 'Fire and Security',
      fireExtinguisher: 'Fire Extinguisher',
      escalator: 'Escalator',
      lightingDevice: 'Lighting Device',
      monitoringSystem: 'Surveillance System',
      guardRoom: 'Guard Room',
      clothesDryingYard: 'Clothes Drying Yard',
      junkyard: 'Junkyard',
      gymAndRhythmClassroom: 'Gym/Rhythm Classroom',
      swimmingPool: 'Swimming Pool',
      ballSportsSpace: 'Ball Sports Area',
      meetingSpace: 'Meeting Room/Social Hall/Banquet Hall',
      rentalRules: 'Rental Rules',
      petRestrictions: 'Pet Restrictions',
      noLimit: 'No Restrictions',
      noPets: 'No Pets Allowed',
      basicEquipment: 'Basic equipment',
      handlingEquipment: 'Handling equipment',
      catOnly: 'Cats',
      dogOnly: 'Dogs',
      other: 'Other Restrictions:',
      furnace: 'Cooking Allowed',
      furnacePlaceholder: 'Yes/No',
      tax: 'Tax Reporting',
      householdRegistration: 'Household Registration',
      godTable: 'God Table',
      identity: 'Identity Requirements',
      officeWorker: 'Office Worker',
      family: 'Family',
      student: 'Student',
      native: 'Local Resident',
      foreigners: 'Foreigner',
      environment: 'House Environment',
      factoryEnvironment: 'Factory Environment',
      imgNotice: 'Photos support jpg, png, gif formats, with a limit of 25MB per photo. Batch upload is available; the first photo will be the cover photo by default.',
      videoNotice: 'A single video is limited to 50MB',
      layoutNotice: 'A single layout image is limited to 50MB',
      housePicture: 'Photos',
      factoryPicture: 'Photos',
      selectFile: 'Select File',
      notUploadedYet: 'Not Yet',
      vrPicture: '360° Photos (VR)',
      video: 'Environmental Video',
      layoutImg: 'layout Diagram',
      houseDescription: 'Description',
      factoryDescription: 'Description',
      contentPLaceholder: 'Enter Content',
      houseDesPlaceholder: 'If any land, location, area, legal information, etc.\nFor example: Land Red Book 45 meters, Hedong County, Chen Dengning Road',
      houseProperty: 'Property Registration',
      factoryProperty: 'Property Registration',
      landProperty: 'Property Registration',
      propertyRightsRegistration: 'Property Rights Registration',
      completed: 'Completed Property Rights Registration',
      notCompleted: 'Transfer contract',
      pinkBook: 'Pink Book',
      buyAndSell: 'Buy and sell handwritten documents',
      legalPurposesOfaAd: 'Legal Purpose of this Advertisement:',
      buildingAreaOfAd: 'Building Area of this Advertisement:',
      rentInAd: 'Rental Floor of this Advertisement:',
      notFilled: 'Not Filled',
      housePropertyDescription: 'If the above data is incorrect or not displayed, you can:',
      housePropertyCheck: '1. Check if the house address is filled in correctly.',
      housePropertyUpload: '2. Upload property rights certificate copy/online property certificate.'
                + ' We will verify and supplement the information for you (uploaded documents are for verification purposes only).',
      buildingTitlePhotocopy: 'Property Rights Certificate Copy',
      houseManagement: 'House Management',
      addObjectToEscrow: 'Add this property to escrow?',
      escrow: 'Escrow',
      yes: 'Yes',
      no: 'No',
      can: 'Allowed',
      not: 'Not Allowed',
      next: 'Next',
      save: 'Save',
      preview: 'Preview',
      returnToEdit: 'Return to Edit',
      bargainingPrice: 'Bargaining Bottom Price',
      toBeLeased: 'To Be Leased',
      layoutDiaram: 'Layout Diagram',
      modifyAddrAlert: ' hours, number of modifications remaining: ',
      usePartitions: 'Use partitions',
      factoryRegistration: 'Factory registration',
      companyRegistration: 'Company registration',
      dividedIntoCompartments: 'Compartment',
      suitableForIndustry: 'Applicable industries',
      disabledIndustry: 'Banned industries',
      buildingStructure: 'Structure',
      floorLoad: 'Floor bearing capacity',
      asphaltRoad: 'Asphalt road',
      concreteRoad: 'Concrete Road',
      alleyStreet: 'Alley street',
      widthOfTheFrontRoad: 'Width from the road',
      buildingHeightLimit: 'Building height limit(meter)',
      unloading: 'Uploading',
      passengerElevator: 'Passenger elevator(Qty)',
      freightElevator: 'Freight elevator(Qty)',
      num: 'Qty',
      totalLandArea: 'Total land area',
      totalFactoryArea: 'Total area',
      constructionArea: 'Construction area',
      specification: 'Specification(meter)',
      meter: 'meter',
      mt: 'mt',
      width: 'Width',
      verticalWidth: 'Vertical width',
      verticalDepth: 'Depth',
      height: 'Height',
      firstFloorHeight: 'First floor height',
      deep: 'Depth',
      baseArea: 'Land area',
      purpose: 'Purpose',
      infrastructure: 'Infrastructure',
      landStatus: 'Land status',
      landEnv: 'Environment',
      landPhotos: 'Photos',
      landDescription: 'Description',
      // eslint-disable-next-line max-len
      landDescriptionPlaceholder: 'If available: land name, project land, land specifications, term of use and legal information, etc.\nFor example: Le Phong residential area, Binh Chuan 42 ward, Thuan An City, Binh Duong Province.\nLand size 4 x16.7=66.8 m2. 100% residential land\nLand use term: Long term.',
      saleBySplits: 'Sale by splits？',
      mainBuilding: 'Total area of main building',
      shareParts: 'Total area of shared parts',
      ancillaryBuildings: 'Total area of ancillary buildings',
      // eslint-disable-next-line max-len
      formWarning: 'Please fill in the above information truthfully. If you do not fill it in truthfully, we will not be responsible for any subsequent disputes or you need to bear legal responsibility',
      officeCategory: 'Office category',
      officeType: 'Office type',
      useArea: 'Usable area',
      ownershipCertificate: 'Area of ownership certificate',
      workstation: 'Number of workstations that can be accommodated',
      unit: 'units',
      carParking: 'Car parking(VND / month)',
      motorParking: 'Motorcycle parking(VND / month)',
      industriesAndCommercialRegistration: 'Industrial and commercial registration',
      handoverOfHouse: 'Handover of the house in current condition',
      surroundingLiving: 'Surrounding living functions',
      surroundingLivingPlaceholder: 'Please enter the surrounding living functions',
      officeEquipment: 'Office equipment',
      otherEquipment: 'Other equipment',
      officeEnv: 'Environment',
      officePhotos: 'Photos',
      shopCategory: 'Shop category',
      shopType: 'Shop type',
      shopInfo: 'Shop Information',
      operatingStatus: 'Operate status',
      customerFlow: 'Customer flow',
      shopEquipment: 'Shop equipment',
      shopEnv: 'Shop environment',
      shopPhotos: 'Shop photos',
    },
    errMsg: {
      titleErr: 'Title cannot be empty',
      cateErr: 'Please select housing category',
      typeErr: 'Please select housing type',
      priceErr: 'Rent cannot be empty',
      depositErr: 'Deposit cannot be empty',
      minLeaseErr: 'Minimum period cannot be empty',
      rentalPeriodErr: 'Rental period cannot be empty',
      mngFeeErr: 'Management fee cannot be empty',
      chooseErr: 'Please select',
      saleFloorErr: 'Sale floor cannot be empty',
      totalFloorErr: 'Total floor cannot be empty',
      emptyErr: 'Cannot be empty',
      squareErr: 'Area cannot be empty',
      rentalFloorInvalidErr: 'The total floor cannot be lower than the rental floor',
      saleFloorInvalidErr: 'Total floor cannot be lower than sold floor',
      imageErr: 'Please upload at least 2 and maximum 25 photos',
      videoErr: 'Upload up to 3 videos',
      layoutErr: 'Upload up to 3 pictures',
      singlePriceErr: 'Please enter the amount',
      minPriceLargerErr: 'The minimum amount cannot be greater than the maximum amount',
      maxPriceSmallerErr: 'The maximum amount cannot be less than the maximum amount',
      addressErr: 'Please enter address',
      unitPriceErr: 'Please enter the total price and area',
      less30CharactersErr: 'Please enter at least 30 characters',
    },
    selectExistingPlan: 'Select a current plan',
    buyPlan: 'Purchase a plan',
    useExistingPlan: 'Use current plan',
    singlePosting: 'Single Posting',
    multiplePlans: 'Multiple Plans',
    averagePrice: 'Average price',
    priceUnit: 'VND / times',
    singlePostingRevise: '3 revisions within 72 hours',
    singlePostingDay: 'Housing listing period: 30 days',
    noExposure: 'No enhanced exposure',
    selectPlan: 'Please select a plan',
    remainingOfItems: 'Remaining postings:',
    items: 'times',
    remainingOfDays: 'Plan remaining days: ',
    days: 'days',
    enhancedExposure: 'Enhanced Exposure',
    valueAddedServices: 'Value Added Services',
    updateSort: 'Update Sorting',
    manualUpdate: 'Manual',
    manualUpdatesDescription: '(Manually click to update sorting)',
    dollar: 'dollar',
    tenThousand: 'ten thousand',
    point: 'points',
    bount: 'times',
    automaticUpdates: 'Automatic',
    automaticUpdatesDescription: '(System automatically updates sorting)',
    regularUpdates: 'Scheduled',
    regularUpdatesDescription: '(Custom update frequency and timing)',
    daily: 'Daily',
    timeToUpdate: 'Update on the hour',
    hour: 'hours',
    minute: 'minutes',
    update: 'Update',
    adServices: 'Advertising Services',
    preferred: 'Preferred',
    preferredDescription: '(Featured in the "Preferred Listings" section on the homepage)',
    recommended: 'Recommended',
    recommendedDescription: '(Featured in the "Recommended Listings" section in the listings)',
    labelingService: 'Labeling Service',
    pin: 'Top Pin',
    pinDescription: '(Prominent top pin icon)',
    choice: 'Choice',
    choiceDescription: '(Prominent editor’s choice icon)',
    expedtedTag: 'Urgent Tag',
    expedtedTagDescription: '(Prominent urgent tag icon)',
    webExposure: 'Web Exposure',
    mobileExposure: 'Mobile Exposure',
    exposureArea: 'Exposure Area',
    effectiveTime: 'Effective Time',
    validityPeriod: 'Validity Period',
    currentlyOwned: 'Currently Owned',
    exposureNotice: 'Note: Exposure effects can be purchased repeatedly, but they will overwrite previously purchased exposure effects! It is recommended to evaluate before purchasing!',
    purchase: 'Purchase',
    effectiveAfterPayment: 'Effective immediately after payment',
    exposurePlan: 'Exposure Plan',
    customAmount: 'Custom',
    recommended88go: 'Recommended by 88go',
    exceptedRank: 'Estimated ranking for today',
    customAmountPlaceholder: 'Enter custom amount',
    lessThan30: '(Minimum points should not be less than 23,000)',
    exposureDays: 'Exposure Days',
    customTime: 'Custom Time',
    customTimePlaceholder: 'Enter custom time',
    details: 'Details',
    amountsPayable: 'Amounts Payable: ',
    publishNow: 'Publish',
    publishSuccess: 'Published Successfully',
    goToHousingInfo: 'Information',
    publishManagement: 'Management',
    warningNotice: '*This page is a preview of the publication page and is not the formal publication page. Be sure to click the publish button to complete the publication process.',
    noPlan: 'There are currently no plans to purchase',
    estimated: 'Estimated publication time',
    estimatedNotice: 'If no time is selected on the current day, the current hour will be added to one hour.',
  },
  memberCenter: {
    home: 'Home',
    account: 'Account',
    message: 'Message',
    history: 'History',
    promotions: 'Promotions',
    transaction: 'Transaction',
    advertisement: 'Advertisement',
    signOut: 'Sign Out',
    edit: 'Edit profile',
    helloMember: 'Hello, Member',
    switchIdentity: 'Switch Identity',
    myAgent: 'My Agent',
    promotionUrl: 'Promotion URL',
    copy: 'Copy',
    vipTitle: 'publication is a good helper to help you close the deal easily!',
    vipDes: 'Enjoy multiple publications and receive free help from dedicated personnel to publish items',
    numberOfRegistered: 'Registered',
    numberOfPublishers: 'Publishers',
    numberOfPublications: 'Number of publications',
    signUpBonus: 'Sign up bonus',
    publicationBonus: 'Publication bonus',
    promotionTotal: 'Promotion total',
    promotionTotalBonus: 'Promotion total bonus',
    startTime: 'start',
    endTime: 'end',
    downline: 'Downline name',
    publishStatus: 'Publish status',
    effectiveQuantity: 'Effective quantity',
    invalidQuantity: 'Invalid quantity',
    registrationTime: 'Registration time',
    objectName: 'Object name',
    invalidReason: 'Invalid reason',
    peopleUnit: 'people',
    dollar: 'dollar',
    totalIncome: 'Total Income This Month',
    totalCommission: 'Total Commission',
    myPoints: 'My Points',
    recharge: 'Recharge',
    storedRecord: 'Records',
    basicPlan: 'Basic',
    items: 'Items',
    purchasePlan: 'Purchase',
    projectManagement: 'Management',
    recentFavorites: 'Recently Favorited Listings',
    more: 'View More',
    noFavorite: 'You have no favorited listings at the moment',
    see: 'Explore Now',
    personalInfo: 'Personal',
    invoiceManagement: 'Invoice Management',
    changePhoto: 'Change',
    changeSubAccountPhoto: 'Change',
    photoLimit: '*To upload a profile picture, please provide a clear, close-up, head-and-shoulders photo.<br>*Supported image formats: jpg, gif, png; Size should be less than 1MB.',
    workPhotoLimit: '*Supported image formats: jpg, gif, png; Size should be less than 1MB.',
    changeGroupPhoto: 'Change',
    form: {
      name: 'Name',
      namePlaceholder: 'Enter Name',
      gender: 'Gender',
      genderPlaceholder: 'Select Gender',
      phoneNumber: 'Phone',
      change: 'Change',
      mailPlaceholder: 'Enter Email',
      pwd: 'Password',
      set: 'Set',
      notSet: 'Not Set',
      changePwd: 'Change',
      link: 'Link',
      unlink: 'Unlink',
      rating: 'Rating',
      qrcodeDownload: 'QR Code Download',
      realName: 'Real Name',
      certificationStatus: 'Certification Status',
      notCertified: 'Not Certified',
      certificationSuccess: 'Verified',
      idCard: 'ID Card Number',
      idCardPlaceholder: 'Enter ID Card Number',
      approvalDate: 'Approval Date',
      approvalDatePlaceholder: 'Please enter the approval date',
      introducer: 'Referrer',
      idCardPicture: 'ID Card Pictures',
      certificateType: 'Certificate Type',
      certificatePicture: 'Certificate Picture',
      certificatePicturePlaceholder: 'Upload Image File',
      saleperson: 'Real Estate Salesperson',
      agent: 'Real Estate Agent',
      certificatePictureNotice: 'Please ensure that your name, certificate number, ID card number, etc. are clearly visible and cover important barcode information. Cannot be larger than 50M.',
      idNumberRemark: '(We strictly protect your personal information; this number will not be displayed publicly)',
      idNumberContent: 'See description for details.',
      accountOwnership: 'Account Ownership',
      companyAccount: 'Corporate Account',
      branchAccount: 'Branch Account',
      other: 'Other',
      emergencyContact: 'Emergency Contact',
      contactIdentity: 'Contact Person Identity',
      contactIdentityPlaceholder: 'Select Contact Person Identity',
      contactPhone: 'Contact Person Phone',
      brokerageName: 'Brokerage Name',
      brokerageNamePlaceholder: 'Enter Brokerage Name',
      workArea: 'Work Area',
      company: 'Branch',
      companyName: 'Company Name',
      belongToGroup: 'Are you a branch member of the group?',
      joinToGroup: 'Join',
      branchName: 'Branch Name',
      hideHouseDetail: 'Hide Company Information on House Detail and Store Pages',
      position: 'Position',
      positionPlaceholder: 'Select Position',
      branchType: 'Branch Type',
      directSale: 'Direct Store',
      franchise: 'Franchise Store',
      updateAccountPlan: 'Do you own multiple branchespurchaseAgain and are worried about how to manage them?',
      updateAccountDes: 'We provide an account upgrade plan suitable for the group! Allows you to easily manage your branches and keep track of real-time business status.',
      updateBtnText: 'Upgrade',
      upgradeAccountTitle: 'Are you worried about how to manage your branch?',
      currentPlan: 'Current upgrade plan',
      noPlan: 'No Plan',
      subscriptionTime: 'Subscription Time',
      settlementType: 'Settlement Type',
      projectFee: 'Plan Fee',
      subscriptionStatus: 'Status',
      storeName: 'Store Name',
      storeNamePlaceholder: 'Enter Store Name',
      serviceSlogan: 'Service Slogan',
      serviceSloganPlaceholder: 'Enter Service Slogan',
      serviceArea: 'Service Area',
      addService: 'Add Service Area (Up to 3 Items)',
      familiarCommuntity: 'Familiar Community',
      experience: 'Experience',
      year: 'Year',
      month: 'Month',
      to: 'To',
      in: 'In',
      branch: 'Branch',
      takeOffice: 'Take Office',
      addExperience: 'Add Experience (Up to 3 Items)',
      certificateHonors: 'Certificate Honors',
      yearOfExperience: 'Experience',
      certName: 'Certificate Name',
      get: 'Received',
      addCert: 'Add Certificate (Up to 3 Items)',
    },
    notice: 'Notice:',
    noticeParagraph1: '1. Invoice processing date for 88go: The invoice will be sent within 15 working days after the member\'s deposit.',
    noticeParagraph2: '2. If you still need a physical invoice, please select the option to request a physical invoice during the deposit process.'
            + ' The completed invoice will be sent within 7 working days after the request is completed.',
    noticeParagraph3: '3. If you need a triplicate invoice, please contact us with your billing information, unified business number, and invoice delivery address.'
            + ' Our staff will assist you with the process.',
    noticeParagraph4: '提供抬头、统编及发票寄送地址，我们将有专人为您处理。',
    save: 'Save Information',
    invoiceList: 'Invoice List',
    changePhoneDescription: 'Please enter your new phone number below',
    phone: 'Phone',
    phonePlaceholder: 'Enter Phone Number',
    sendVerficationCode: 'Send Verification Code',
    sendCode: '4-digit verification code sent to',
    verify: 'Verify',
    verifySuccess: 'Phone number changed successfully. You will be redirected to the account management page.',
    confirm: 'Confirm',
    changePwd: 'Change Password',
    changePwdDescription: 'Please enter your new password below',
    oldPwd: 'Old Password',
    oldPwdPlaceholder: 'Enter Old Password',
    newPwd: 'New Password',
    newPwdPlaceholder: 'Please enter a password of 8 to 12 characters including alphanumeric characters',
    confirmNewPwd: 'Confirm New Password',
    changeSuccessDescription: 'Password changed successfully. Please log in again.',
    websiteNotification: 'Website',
    houseQA: 'House Q&A',
    editMessage: 'Edit',
    deleteMessage: 'Delete',
    impeach: 'Report',
    matchingObject: 'Matching Objects',
    previous: 'Previous',
    next: 'Next',
    chooseAll: 'Select All',
    readSelected: 'Read Selected',
    deleteSelected: 'Delete Selected',
    matchingText: 'Dear 88go members Hello\nThank you for your support and interest in our rental website.\nWe have completed the following matching based on your rental needs',
    recommendObject: 'Recommend',
    idealDes: 'Welcome to refer to our recommended items and find your ideal item!',
    historyType: 'Type',
    caseNumber: 'Case Number',
    caseNumberPlaceholder: 'Enter Case Number',
    productName: 'Company/Product Name',
    productNamePlaceholder: 'Enter Company/Product Name',
    questionTime: 'Question Time',
    questionTimePlaceholder: 'Enter Date',
    search: 'Search',
    clear: 'Clear',
    title: 'Title',
    publisher: 'Publisher',
    publishType: 'Type',
    status: 'Status',
    rent: 'For Rent',
    sale: 'For Sale',
    noReply: 'No Reply',
    replied: 'Replied',
    viewDetail: 'View Details',
    yourQuestion: 'Your Question',
    userQuestion: 'User Question',
    yourImpeachContent: 'Your Report Content',
    noProcessed: 'Not Processed',
    processing: 'Processing',
    closed: 'Closed',
    publisherReply: 'Publisher Reply',
    customerReply: 'Customer Service Reply',
    reply: 'Reply',
    replayLabel: 'Please enter your reply',
    contentPLaceholder: 'Enter content',
    sendReply: 'Send Reply',
    previousPage: 'Back to Previous Page',
    watchHistory: 'Viewing',
    favoriteObjects: 'Favorite',
    transactionRecords: 'Property Transaction Records',
    establishmentTime: 'Case Establishment Time',
    sort: 'Sort',
    houseType: 'Type',
    area: 'Area',
    noticeText: '*This transaction record only provides property transaction records with 88go.',
    caseType: 'Case Type',
    propertyType: 'Property Type',
    price: 'Amount',
    personalManagement: 'Personal Management',
    performanceReport: 'Performance Report',
    salesPersonReport: 'Salesperson Report',
    accountManagement: 'Account Management',
    overview: 'Agent Overview',
    collectionAccount: 'Collection Account',
    paymentRecords: 'Payment Records',
    changeAccount: 'Change Account',
    totalPerformance: 'Total Performance',
    plantformTotalCommission: 'Platform Total Commission',
    myCommission: 'My Commission',
    startMonth: 'Start Month',
    startMonthPlaceholder: 'Enter Month',
    level: 'Level',
    people: 'People',
    accountWarningNotice: '*The first account is the default collection account. If you need to change the account, please manually drag and change it.',
    addAccount: 'Add Account',
    deleteAccount: 'Delete Account',
    agentRegulations: 'Agent Regulations',
    addAccountDescription: 'Please add your bank account below',
    bank: 'Bank',
    choosePlaceholder: 'Select',
    chooseExprYear: 'Expiration Year',
    chooseExprMonth: 'Expiration Month',
    breanchName: 'Branch Name',
    bankAccount: 'Bank Account',
    enterPlaceholder: 'Enter',
    cashManagement: 'Cash',
    pointsManagement: 'Points',
    plansManagement: 'Plans',
    paymentManagement: 'Payment',
    recharged: 'Recharged',
    expenses: 'Expenses',
    transactionTime: 'Transaction Time',
    type: 'Type',
    remark: 'Remark',
    paymentStatus: 'Payment Status',
    revenue: 'Revenue',
    remitted: 'Remittance',
    point: 'Point',
    points: 'Points',
    rechargedSuccess: 'Recharge successful, remaining points: ',
    palnType: 'Type',
    planName: 'Name',
    purchaseTime: 'Purchase Time',
    expireTime: 'Expiration',
    numberOfPlans: 'Plan Items',
    remainingTime: 'Remaining Time',
    continue: 'Continue',
    checkNotice: 'Please confirm if the selected plan is correct',
    choosePlan: 'Choose Plan',
    period: 'Membership Period',
    day: 'Day',
    planPoint: 'Plan Points',
    additional: 'Published by secretary',
    noAdditionalPurchase: 'No additional purchase',
    additionalPurchase: 'Additional purchases, purchase quantity',
    purchaseCount: 'Purchase quantity',
    additionalPurchaseService: 'Additional purchases services',
    notEnoughPoints: 'Not enough points? Recharge',
    confirmPayment: 'Confirm Payment',
    deleteCard: 'Delete Card',
    creditCardsAcceptedOnly: 'Credit cards only accepted',
    creditCard: 'Credit Card',
    addCreditCard: 'Add Credit Card',
    addNewCard: 'Add Card',
    cardholderName: 'Cardholder Name',
    creditCardNumber: 'Credit Card Number',
    expiration: 'Expiration Date',
    securityCode: 'Security Code',
    securityCodePlaceholder: 'Enter Security Code',
    nameplaceholder: 'Enter Name',
    rentalPlan: 'Rental',
    remainingTransactions: 'Remaining',
    publishedBySecretary: 'Published by secretary',
    notUse: 'Published by secretary and not unused',
    salePlan: 'Sale',
    listing: 'Listing',
    listingDescription: '(Displaying the latest 4 listings on the current page)',
    publishManagement: 'Publish',
    businessManagement: 'Business',
    publish: 'Publish Now',
    noPublish: 'Currently no active listings',
    remaining: 'Remaining Items',
    purchaseAgain: 'Purchase Again',
    subscribeAgain: 'Subscribe Again',
    housesClosed: 'Closed',
    removedHouses: 'Sold',
    editHouse: 'Unlisted',
    numberOfListing: 'Active',
    numberOfSinglePublications: 'Single',
    publishAll: 'All',
    singlePosting: 'Single',
    rentalPlanPublication: 'Rental Plan',
    salePlanPublication: 'Sale Plan',
    publicationNumber: 'Listing Number',
    publicationTime: 'Listing Time',
    housingInformation: 'Information',
    hoisingPrice: 'Price',
    numberOfVisitors: 'Views',
    timeRemaining: 'Remaining',
    operate: 'Operate',
    closeHousing: 'Close',
    dealHousing: 'Deal',
    improveEffect: 'Improve Effect',
    improveEffectDes: 'If you choose to improve the effect, your existing effects will become invalid.<br>Do you want to confirm the effect?',
    updateSort: 'Update Sort',
    currentNumberOfPublications: 'Current Listings',
    remainingListing: 'Remaining Listings',
    proPlan: 'Professional Plan',
    closureReason: 'Reason',
    publishHouse: 'Publish',
    removedTime: 'Sold Time',
    publishAgain: 'Publish Again',
    numberOfTransactions: 'Number of Transactions',
    renew: 'Renew',
    sortPlan: 'Sorting plan',
    sortTime: 'Sorting time',
    adPlan: 'Advertising',
    adTime: 'Advertising time',
    labelingPlan: 'Labeling plan',
    labelingTime: 'Labeling time',
    enhanceExposure: 'Enhance Exposure',
    exposureArea: 'Exposure Area',
    exposureType: 'Exposure Methods',
    exposureTime: 'Exposure Time',
    valueAddedServices: 'Service',
    valueAddedCategory: 'Category',
    valueAddedOption: 'Option',
    valueAddedTime: 'Time',
    publicationDate: 'Publication',
    releaseDate: 'Release',
    estimated: 'Estimated',
    listingStatus: 'Listing Status',
    publicationTitle: 'Listing Title',
    deleteListing: 'Delete Listing',
    editListing: 'Edit Listing',
    editHouseDetail: 'Edit',
    currentEffects: 'Current Effects',
    storeManagement: 'Store',
    certification: 'Certification',
    workData: 'Work Information',
    uploadCertification: 'Upload Professional Certificate',
    waitingForReview: 'Waiting for Review',
    reviewSuccessful: 'Review Successful',
    step1Des: '*After submitting your documents, customer service will review them within 24 hours. You can check the review results in the Broker Professional Certification section.',
    submit: 'Confirm Submit',
    submissionSuccess: 'Data submitted successfully. Customer service will review it within 24 hours. Please be patient.',
    retrunToMemberHome: 'Return to Member Homepage',
    emergencyNotice1: '1. Emergency contacts are just one way to notify members and cannot exercise account rights on behalf of members.',
    emergencyNotice2: '2. Notifying emergency contacts is considered as notifying the member. Therefore, if the contact information changes, it should be updated immediately.'
            + ' If the emergency contact refuses to answer, does not assist in notifying the member, or cannot be contacted, resulting in the member not receiving the notification,'
            + ' it is the responsibility of the member and not related to 88go.',
    storeData: 'Store Data',
    storeQA: 'Store Q&A',
    flyers: 'Flyer Creation',
    storeInfo: 'Store Info',
    businessExperience: 'Business Experience',
    introduce: 'Self Introduction',
    activate: 'Activate',
    isActivateStore: 'Whether to enable the shop?',
    qaNumber: 'Q&A Number',
    questionAccount: 'Question Account',
    choose: 'Select',
    selectAll: 'Select All Houses',
    createFlyers: 'Create Flyers',
    flyersProductionRecord: 'Records',
    checked: 'Selected',
    flyersListing: 'Listing(s)',
    productionDate: 'Production date',
    fileName: 'File name',
    view: 'view',
    download: 'download',
    delete: 'delete',
    subAccount: 'Sub-account',
    total: 'Total',
    subAccountGroup: 'Sub-account Group',
    addSubAccount: 'Add Sub-account',
    subAccountInfo: 'Sub-account Information',
    serialNumber: 'Serial',
    lastLoginTime: 'Last Login',
    editAccount: 'Edit Account',
    securityCodeOverErr: 'Incorrect Security Code',
    securityCodeEmptyErr: 'Security Code Cannot Be Empty',
    cardholderNameEmptyErr: 'Cardholder Name Cannot Be Empty',
    cardNumberEmptyErr: 'Credit Card Number Cannot Be Empty',
    cardTypeError: 'Invalid Credit Card Format',
    advertisementRecord: 'Records',
    advertisementApplicationRecord: 'Application Records',
    AdsRunning: 'Ads Running',
    closeAds: 'Close Ads',
    adsArea: 'Block',
    adsInfo: 'Information',
    numberOfDaysRemaining: 'Remaining',
    adsPlan: 'Ads Plan',
    sidebarNotice: 'Please upload 199px * 600px for homepage sidebar advertisement',
    popupsNotice: 'Please upload 300px * 300px for pop-up ads',
    floatNotice: 'Please upload 970px * 90px for floating ads',
    carouselNotice: 'Please upload 1920px * 533px for the web version of the homepage carousel image.',
    carouselNotice2: 'Please upload 428px * 200px for the mobile version of the home page carousel image',
    applyForAds: 'Apply',
    applyNotice: 'Please fill in the advertisement information',
    publishTime: 'Publication Time',
    adImage: 'Image',
    urlPlaceholder: 'Please enter the URL',
    qrcodePlaceholder: 'Please upload the QR Code image',
    backToAdManagement: 'Back',
    adNotice: 'Please upload 199 x 600px for the homepage sidebar.',
    promotionsRegulations: 'Promotions Regulations',
    promotionsExpired: 'Expired',
    myRequirements: 'Requirements',
    birthday: 'Birthday',
    birthdayPlaceholder: 'Please select birthday',
    socialMedia: 'Social media',
    housingOptions: 'Housing',
    houseingType: 'Type',
    preferenceRegion: 'Region',
    preferenceArea: 'Area',
    amountRange: 'Amount',
    specialRequirements: 'Others',
    specialRequirementsPlaceholder: 'Enter conetent',
    requirementsDes: 'Fill in your housing requirements and let 88go provide you with suitable housing information!',
    clientManagement: 'Client',
    newTask: 'New Task',
    editTask: 'Edit Task',
    allDayTask: 'All-Day',
    inComplete: 'Incomplete',
    inProgress: 'In progress',
    completed: 'Completed',
    cancel: 'Cancel',
    taskNumber: 'Number',
    taskName: 'Task Name',
    clientName: 'Name',
    taskContent: 'Content',
    report: 'Report',
    checkIn: 'Check In',
    taskList: 'Tasks',
    schedule: 'Schedule',
    activity: 'Activity',
    salePersonList: 'Business',
    monthly: 'Monthly',
    weeklyReport: 'Weekly Report',
    toBeReported: 'To be reported',
    reported: 'Reported',
    lastContactTime: 'Last contact time',
    reportContactInfo: 'Report',
    numberOfBuyersAndSellersToBeReported: 'Quantity required to be reported by the buyer and seller of homeowners',
    numberOfBuyersAndSellersOfClientToBeReported: 'Quantity required to be reported by the buyer and seller of customers',
    numberOfRentalHouseOwnersToBeReported: 'Quantity required to be reported by the lessor homeowners',
    numberOfRentalCustomerToBeReported: 'Quantity required to be reported by the lessor customers',
    contactTime: 'Contact time',
    contactTimePlaceholder: 'Please enter contact time',
    contactInfo: 'Contact information',
    reportTime: 'Report time',
    performanceAmount: 'Performance amount ',
    signAndServiceFee: '(signing amount/service fee)',
    mainSalesperson: 'Main salesperson',
    signingAmountPlaceholder: 'Please enter the signing amount',
    monthlyActivity: 'Monthly Activities',
    missionHistory: 'History',
    targetTotal: 'Total target for this month',
    totalTransaction: 'Total transaction for this month',
    targetTotalOfMonth: 'Total revenue achieved this month',
    performance: 'Performance',
    leaseEntrustment: 'Lease Entrustment(items)',
    buyingEntrustment: 'Transaction entrustment (items)',
    leaseVisit: 'Lease (items)',
    transactionVisit: 'Transaction Visit(items)',
    goalSetting: 'Setting',
    performanceRegistration: 'Registration',
    addInBatches: 'Add',
    addNewGoal: 'Add new goals in batches',
    performanceGoals: 'Goal',
    addMonthlyGoal: 'Add monthly goals',
    branchList: 'Branch List',
    branchInfo: 'Branch Information',
    branchNum: 'Number Of Branches',
    branchApplication: 'Apply',
    editBranch: 'Edit Branch',
    addNewBranch: 'Add Branch',
    addGroupBranch: 'New Group Branch',
    branchAccount: 'Account',
    ownerPosition: 'Owner Position',
    branchNotice: 'If your branch application is not reviewed within 7 days, it will be automatically returned. Please be sure to complete the review within the time limit.',
    notReviewed: 'Not Reviewed',
    applyForAccount: 'Account',
    applyForAccountPlaceholder: 'Please enter your account name or mobile phone number',
    applyStatus: 'Apply Status',
    applyStatusPlaceholder: 'Please select review status',
    replyStatus: 'Reply status',
    replyStatusPlaceholder: 'Please select reply status',
    applyAccountInfo: 'Account Information',
    applyBranchInfo: 'Branch Information',
    applyForReview: 'Apply For Review',
    approved: 'Approved',
    notApproved: 'Not Approved',
    trade: 'Trade',
    lease: 'Lease',
    houseOwner: 'Homeowner',
    client: 'Client',
    transactionItems: 'Transaction Items',
    transactionType: 'Transaction Type',
    homeownerType: 'Homeowner Type',
    entrustment: 'Entrustment',
    homeownerName: 'Homeowner\'s Name',
    homeownerAndContract: 'Homeowner/Contract',
    homeownerInfo: 'Homeowner Information',
    itemsSaleInfo: 'Item Sales Information',
    salesInfo: 'Salesperson Information',
    clientRequest: 'Client\'s Request',
    entrustedAudit: 'Entrusted Audit',
    contactAudit: 'Contact Audit',
    transactionNotReviewed: 'Transaction',
    leaseNotReviewed: 'Lease',
    buyerAndSellerOfHomeowners: 'Buying and selling of homeowners',
    buyerAndSellerOfClient: 'Buying and selling of customers',
    leasingHomeowners: 'Leasing homeowners',
    leasingClient: 'Leasing customers',
    salespersonReportInfo: 'Salesperson report information',
    contactDetails: 'Contact details',
    contactRecord: 'Contact records',
    reviewedDes: 'Please confirm whether the appointment method is correct, and then click Approval.',
    contractDes: 'Please confirm whether the service fee is correct, and click to approval.',
    chooseClientType: 'Please select the appropriate customer type and identity',
    clientIdentity: 'Identity',
    responsibleForSalesperson: 'Salesperson',
    demandArea: 'Area requirements',
    demandHouseCategory: 'Housing category requirements',
    demandHouseType: 'Housing type requirements',
    budget: 'Budget',
    taskDate: 'Date',
    taskFormat: 'Mode',
    taskLocation: 'Location',
    sameAsClient: 'Same as client contact address',
    taskInfo: 'Task Information',
    clientInfo: 'Client Information',
    contactName: 'Contact name',
    contactAddress: 'Address',
    reportInfo: 'Report Information',
    contractAmount: 'Service fee',
    staringTime: 'Starting time',
    endingTime: 'Ending time',
    visitTime: 'Visit time',
    reportImgNotice: 'A single photo is limited to 50MB and can be uploaded in batches. Please make sure you are in the photo.',
    reportPic: 'Photos',
    reportContent: 'Content',
    addNewClient: 'Add',
    clientNumber: 'Number of clients',
    homeownerNumber: 'Number of homeowners',
    editClient: 'Edit',
    visitRecord: 'Visit record',
    visitDate: 'Visit date',
    taskStatus: 'Task status',
    cancelReason: 'Reason',
    taskDetail: 'Task Details',
    deleteTask: 'Delete Task',
    willDeleteTask: 'You will delete task',
    checkToDelete: 'Do you confirm deletion?',
    salesPerson: 'Salesperson',
    vipsNumber: 'Number of VIPs',
    totalNumberOfPublication: 'Total Entrusted This Month',
    commissionHistory: 'Entrusted History',
    vipsTotal: 'Total VIPs',
    commissionAccount: 'Name',
    commissionAccountPhone: 'Contact phone',
    rank: 'Rank',
    totalTask: 'Total tasks',
    visitPercent: 'Visit ratio',
    branchAddrPlaceholder: 'Please enter the branch address',
    salesHistory: 'Salesperson history',
    month: 'Month',
    targetMonth: 'Target month',
    salesGoal: 'Salesperson Goals',
    transactionPerformance: 'Transaction performance',
    autoSubscribe: 'Automatically renewed',
    upgradeAccount: 'Upgrade',
    morePlan: 'More',
    subscribing: 'Subscribing',
    expired: 'Expired',
    reserve: 'Extend',
    noLicense: 'Your qualifications have not yet been certified. Please be sure to complete the certification.',
    goToCertification: 'Go',
    goAddNewClient: 'Go',
    groupName: 'Group Name',
    waitingForReply: 'Waiting for reply',
    applicationRecords: 'Branch application record',
    addTime: 'Add Time',
    setBranch: 'Your account is set as a group branch',
    setBranchDes: 'set your account as a branch. Please confirm whether to change this identity?',
    becomeBranchMember: 'You have become a branch member of the group!',
    addRequiredAreas: 'Add new areas of demand',
    assistedSalesperson: 'Assisted salesperson',
    chooseSalesperson: 'Please select a salesperson',
    categoryOfHousingDemands: 'Category of housing demand',
    categoryOfOfficeDemands: 'Category of office demand',
    categoryOfShopDemands: 'Category of shop demand',
    typeOfHousingDemands: 'Type of housing demand',
    typeOfFactoryDemands: 'Type of factory demand',
    typeOfLandDemands: 'Type of land demand',
    typeOfOfficeDemands: 'Type of office demand',
    typeOfShopDemands: 'Type of shop demand',
    areaDemand: 'Area demand',
    layoutDemand: 'Layout demand',
    performanceAudit: 'Audit',
    contractAudit: 'Contract Audit',
    secretaryAssignment: 'Secretary Assignment',
    unassignedVIP: 'Unassigned VIP',
    vipsInfo: 'VIP information',
    planInfo: 'Plan information',
    assignmentStatus: 'Assignment status',
    responsibleSecretary: 'Responsible secretary',
    generalClient: 'General',
    vip: 'VIP',
    numberOfEntrustPublication: '委托刊登',
    numberOfComplimentaryPublications: '赠送委托刊登数',
    secretaryInfo: 'Secretary information',
    changeSecretary: 'Change secretary',
    entrustmentRecord: '委托纪录',
    numberOfPublication: 'Entrust',
    branchDetail: 'Branch Detail',
    checkInTime: 'Check-in time',
    checkInLocation: 'Check-in location',
    taskCheckIn: 'Task check-in',
    notCheckIn: 'Not check-in',
    taskCheckInDes: 'Have you confirmed your arrival at the visiting location?',
    hasClientInfo: 'Obtain client information',
    hasClientInfoDes: 'Did you obtain client information during the task?',
    realPrice: 'Actual Price Registration',
    realPriceDes: 'This information is only used for website statistics. After the actual price registration is reviewed for 3-7 days, 10 points will be rewarded to members.',
    dealPrice: 'Transaction amount',
    addNewSecretary: 'Add New Secretary',
    secretaryManagement: 'Secretary Management',
    secretaryAccount: 'Secretary account',
    freeDedicatedPublication: 'Published by secretary',
    expiredOn: 'Expires on',
    clickFirst: 'Please click here first',
    downloadTemplate: 'Download example',
    instructionsForUse: 'Instructions for use',
    importDescription1: 'Please download the import sample file first.',
    importDescription2: 'Fill in the corresponding object information according to the form content.',
    importDescription3: 'Go back to 88go Member Center > Publication > Unlisted, click Upload Property File, and upload the bulk import property table.',
    importDescription4: 'If the uploaded file is correct, the property information will be automatically imported into the unpublished list.',
    uploadFile: 'Upload file',
    field: 'Field',
    friends: 'Recommended by friends',
    inviteFriendsLink: 'Link',
    totalRewardsThisMonth: 'Total rewards this month',
    personalPublishingRewards: 'Personal publication rewards',
    firstLevelRewards: 'First level rewards',
    secondLevelRewards: 'Second level rewards',
    thirdLevelRewards: 'Third level rewards',
    bonusStatistics: 'Bonus statistics',
    numberOfPersonalListings: 'Number of items published by individual',
    numberOfFriendsPublishing: 'Number of items published by friends',
    numberOfPeoplePublishedByFriends: 'Number of people published by friends',
    numberOfPublishingOnTheSecondLevel: 'Number of publication on the second level',
    numberOfPeoplePublishingOnTheSecondLevel: 'Number of people publication on the second level',
    numberOfPublishingOnTheThirdLevel: 'Number of publication on the third Level',
    numberOfPeoplePublishingOnTheThirdLevel: 'Number of people publication on the third level',
    memberName: 'Member name',
    memberNamePlaceholder: 'Please enter member name',
    friendsInfo: 'Friends information',
    numberOfPeopleInTheDownLine: 'Number of subordinates',
    numberOfPeoplePublishedInTheDownLine: 'Number of subordinates who published',
    totalNumberOfListingsBySubordinates: 'Total number of listings by subordinates',
    friendsRule: 'Quy định',
  },
  forumPage: {
    enTitle: 'Nền tảng thảo luận',
    forumHome: 'Home',
    myArticles: 'My articles',
    articleType: 'Type',
    searchArticlePlaceholder: 'Search article title or author',
    numberOfArticles: 'Articles',
    numberOfReplies: 'Replies',
    publishArticle: 'Publish',
    all: 'All',
    general: 'General',
    featured: 'Featured',
    popular: 'Popular',
    type: 'Type',
    time: 'Time',
    repliesViews: 'Replies/Views',
    views: 'Views',
    login: 'Login',
    canPublish: 'You can publish articles and leave comments',
    registerAt: 'Registered at',
    edit: 'edit',
    delete: 'delete',
    reply: 'reply',
    replyTitle: 'Reply',
    replyDescription: 'Enter your reply content and be sure to comply with the forum rules',
    send: 'Send',
    rule: 'Rule',
    ruleDes: 'Please be sure to read the forum rules carefully before submitting articles. All members must abide by the above principles. If you disagree, please do not post comments here.',
    // eslint-disable-next-line max-len
    ruleItem1: 'Members of the 88go Trading Network are free to comment on all articles. The topics and content on this forum do not represent the position of 88go. Please abide by legal regulations when posting comments and comply with the purpose of this forum. Splitting is strictly prohibited.',
    // eslint-disable-next-line max-len
    ruleItem2: 'Before publishing an article, members must confirm that the content (pictures) of the article they post does not infringe the copyright, trademark, patent and other rights of others. If any legal liability arises from articles published by members, they will be borne by the members themselves.',
    // eslint-disable-next-line max-len
    ruleItem3: "Members agree not to post anything in the forum that is fraudulent, defamatory, false, abusive, vulgar, harmful, harassing, obscene, profane, sexually oriented, threatens to invade another person's privacy, or violates any Information on legal regulations. If found to be reported, 88go has the right to delete it on its own. If the circumstances are serious, the member's qualifications will be revoked.",
    ruleItem4: 'Please do not leave personal privacy information such as your ID number and address to avoid unauthorized use. 88go will not be responsible for the management of this situation.',
    articleTitle: 'Title',
    articleTitlePlaceholder: 'Please enter the article title',
    articleContent: 'Content',
    pin: 'Pin',
    deletedMsg: 'Message has been deleted',
    replyTo: 'Reply to',
    editArticle: 'Edit',
    joinComments: 'Join the discussion after logging in or registering',
  },
  dialog: {
    noLoginYet: 'Not Logged In Yet',
    noLogin: 'To learn more, please log in or register',
    goLogin: 'Go to Login',
    cancel: 'Cancel',
    guestIdentity: 'Current Identity: Buyer/Tenant',
    guestDescription1: 'You are currently identified as a buyer/tenant and cannot post rental/sale ads.',
    guestDescription2: 'Do you want to switch your identity?',
    switchIdentity: 'Switch Identity',
    confirmUpload: 'Confirm Upload',
    noPoints: 'Not enough points',
    noPointsDescription: 'You currently have insufficient points available, please recharge before purchasing.',
    remainPoints: 'remaining:',
    pointsRequired: 'point required',
    needToStore: 'need to recharge points',
    store: 'Recharge',
    storedPoints: 'Recharge Points',
    paymentMethods: 'Payment',
    transfer: 'transfer',
    payment: 'Payment',
    addSubAccount: 'Add sub-account',
    editSubAccount: 'Edit sub-account',
    subAccountInfo: 'Sub-account Information',
    memberStatus: 'Status',
    deleteMsg: 'Delete message',
    deleteMsgDes: 'Are you sure you want to delete message?',
    confirmDeletion: 'Confirm deletion',
    reporting: 'Report',
    reportContent: 'Report content',
    reportNow: 'Report',
    adApplicationDetails: 'Ad application details',
    adInfo: 'Ad Information',
    applicationTime: 'Application time',
    applicantName: 'Name',
    reviewResults: 'Review results',
    reason: 'Reason',
    expired: 'Expired',
    comfirmClose: 'Confirm',
    closeDes: 'Are you sure to close this object?',
    // eslint-disable-next-line max-len
    closeNotice: '*After the listed object is closed, its listing time will be calculated as usual. If it is closed and reopened again within the validity period, there is no need to pay for listing again.',
    dealHouse: 'Do you confirm the transaction of this object?',
    dealNotice: '*If the listed object is relisted after it is sold, the recharge fee will need to be charged again.',
    deleteArticle: 'Delete article',
    deleteArticleNotice: 'Are you sure to delete the article? This action will not be undone',
    deleteComment: 'Delete reply',
    deleteCommentNotice: 'Are you sure to delete the reply? This action will not be undone',
    promotionDetail: 'Promoter downline details',
    downlineName: 'Promoter downline name',
    houseClosed: 'The object has been closed. You will be returned to the previous page after confirmation.',
    storeClosed: 'The shop has been closed. You will be returned to the previous page after confirmation.',
    orderTime: 'Order time',
    orderAmount: 'Order amount',
    idContent: 'ID Card Upload Instructions',
    idCardCover: 'Please cover the important barcode information (QR Code) when uploading your ID card',
    idCardDescription: 'And make sure other ID card information and other content are clearly visible. Pictures must be in jpg, gif, or png format and smaller than 50M.',
    thirdPartyCondition: 'Amount required to be paid by third parties: Minimum limit: 10,000 VND Maximum limit: 200,000,000 VND',
    applicantPosition: 'Applicant\'s position',
    belongToGroup: 'Group',
    branchAddr: 'Branch address',
    addBranch: 'Please confirm whether the branch application content is correct, and then click Approval.',
    accountName: 'Account name',
    joinTheGroup: 'Join the group',
    refuseToJoinTheGroup: 'Refuse to join the group',
    checkDes: 'Do you want to confirm this operation?',
    deleteSecretary: 'Delete secretary',
    deleteSecretaryNotice: 'Are you sure to delete the secretary? This action will not be undone',
    importDataError: 'Import data error',
    // eslint-disable-next-line max-len
    importDataErrorNotice: 'There is an error in the imported data. Please confirm the file content and error code below, correct it and upload it again. If you have any questions, please contact customer service.',
    sheetName: 'Sheet name',
    systemError: 'System Error',
    systemErrorNotice: 'System error, please try again later. If you have any questions, please contact customer service.。',
    checkContactAudit: 'Are you sure you want to report back via this contact?',
  },
  clear: 'Clear',
  buyer: 'Buyer/Tenant',
  owner: 'Owner/Agent',
  estateAgent: 'Agent/Real Estate Broker',
  estateAgency: 'Real Estate Agency',
  save: 'Save',
  saveAndChange: 'Save Changes',
  enable: 'Enable',
  disable: 'Disable',
  selectLanguage: 'Select Language',
  language: 'Language',
  transfer: 'transfer',
  bankName: 'Bank Name',
  bankCode: 'Bank Code',
  bankAccount: 'Bank Account',
  storedImmediately: 'Recharge',
  rechargedAmount: 'Amount',
  remittanceVerification: 'Remittance verification',
  submit: 'Submit',
  group: 'Group',
  branch: 'Branch',
  code: {
    1: 'Success',
    1001: 'Token Expired',
    1002: 'Invalid Token',
    1003: 'Token Unauthorized',
    2001: 'Invalid Request',
    2002: 'System Error',
    2003: 'Database Error',
    2004: 'No Permission',
    2005: 'Data Not Found',
    3001: 'Invalid Account',
    3002: 'Invalid Password',
    3003: 'Account Already Exists',
    3004: 'Phone Number Already Exists',
    3005: 'Multiple Verification Code Requests',
    3006: 'Invalid Verification Code',
    3007: 'Verification Code Expired',
    3008: 'Account Already Verified',
    3009: 'Password Already Exists',
    3010: 'Password Does Not Exist',
    3011: 'Review Already Exists',
    3012: 'Review Closed',
    3013: 'Account Disabled',
    3014: 'Cannot Switch Current Role',
    3015: 'House Not Found',
    3016: 'Store Closed',
    3017: 'Store Not Found',
    3018: 'Account Not Found',
    3019: 'Qualification Under Review',
    3020: 'Qualification Verified',
    3021: 'Question and Answer Not Found',
    3022: 'Duplicate Posting Within One Hour',
    3023: 'Duplicate Comment Within One Minute',
    3024: 'Invalid Phone Number',
    3025: 'Invalid Ad Status',
    3026: 'Payment Method Not Allowed',
    3027: 'Order Status Error',
    3028: 'Url Expired',
    3029: 'Url Invalid',
    3030: 'Not in Activity Time',
    3031: 'An error occurred while replying to the message',
    3032: 'Task has expired',
    3033: 'The sub-account does not belong to the user',
    3034: 'The maximum number of group branches has been reached',
    3035: 'The branch is already in the group or the branch status is incorrect',
    3036: 'The branch audit record does not exist or has been audited',
    3037: 'Check-in not completed',
    3038: 'The client has not yet appointed an audit',
    4001: 'Not the Owner of the Listing or Invalid Listing Status',
    4002: 'Listing Type Does Not Match Plan Type',
    4003: 'No Plans Available',
    4004: 'No Remaining Listing Slots',
    4005: 'No Remaining Listing Period',
    4006: 'Insufficient Account Points',
    4007: 'Listing Already Applied Same Type of Value-Added Plan',
    4008: 'Listing Already Applied Same Type of Exposure Plan',
    4009: 'Incorrect Account ID, Role, or Account Sub ID',
    4010: 'Renewal Plan Type Mismatch',
    4011: 'No Manual Update Records',
    4012: 'Insufficient Manual Update Count',
    4013: 'Manual Update Time Less Than 1 Hour',
    4014: 'The account role is an agency or an agency company. You need to fill in the information before publishing. You can only publish after passing the review',
    4015: 'Exposure amount cannot be less than 19',
    4016: 'It has been closed by the backend and cannot be re-posted',
    4017: 'Only one group plan can be booked',
    4018: 'Group plans can only be upgraded',
    4020: 'Not a company account',
    4021: 'Not the VIP\'s secretary',
    4022: 'The plan is published by secretary and has not yet been purchased.',
    4023: 'The sub-account is not a secretary',
    4024: 'There are still VIPs in the service and cannot be deleted.',
    4025: 'The number of times or modification time of the object address in the listing has exceeded',
    5001: 'xlsx error',
    5002: 'Field is empty',
    5003: 'Wrong field length',
    5004: 'Wrong field number',
    5005: 'File error',
  },
  abuse_house: {
    status: {
      0: 'Not Processed',
      1: 'In Progress',
      2: 'Closed',
    },
  },
  account: {
    gender: {
      1: 'Male',
      2: 'Female',
      3: 'Unknown',
    },
    status: {
      0: 'Disabled',
      1: 'Enabled',
    },
    verify_stat: {
      0: 'Not Verified',
      1: 'Verified',
    },
  },
  account_point_history: {
    type: {
      0: 'All',
      1: 'Purchase Points',
      2: 'Publishing Plan',
      3: 'Membership Plan',
      4: 'Exposure Rate',
      5: 'Value-Added Service',
      6: 'Advertising Plan',
      7: 'Agency Plan',
      8: 'Manual Deposit',
      9: 'Promotions',
      10: 'Group Plan',
      11: 'Upgrade Points Difference',
      12: 'Actual Price Registration Bonus',
      13: 'Secretary Published',
    },
    daily: 'Days / Everyday:',
  },
  plan_type: {
    type: {
      0: 'All',
      1: 'Basic',
      2: 'Rental',
      3: 'Sale',
      4: 'Advertise',
    },
  },
  account_qi: {
    cert_type: {
      0: '',
      1: 'Salesperson',
      2: 'Manager',
    },
    ownership: {
      1: 'Group',
      2: 'Branch',
      3: 'Other',
    },
    company_position: {
      1: 'Chairman',
      2: 'General Manager/CEO',
      3: 'Manager',
      4: 'Store manager',
      5: 'Broker/Intermediary',
      6: 'Assistant',
      7: 'Other',
    },
    e_identity: {
      1: 'Secretary',
      2: 'Other',
    },
    store_type: {
      1: 'Direct',
      2: 'Franchise',
    },
    status: {
      0: 'Not Approved',
      1: 'Under Review',
      2: 'Approved',
      3: 'Rejected',
    },
  },
  account_role: {
    type: {
      1: 'Buyer/Tenant (Member)',
      2: 'Landlord/Agent',
      3: 'Manager/Intermediary',
      4: 'Real Estate Agency',
      5: 'Sub-Account',
    },
  },
  account_sub: {
    gender: {
      1: 'Male',
      2: 'Female',
      3: 'Unknown',
    },
    status: {
      0: 'Disabled',
      1: 'Enabled',
    },
  },
  ad_apply: {
    block: {
      1: 'Homepage Sidebar',
    },
    type: {
      1: 'Frontend Application',
      2: 'Backend Application',
    },
    status: {
      0: 'Not Submitted',
      1: 'Under Review',
      2: 'Approved (Publishing Time Set)',
      3: 'Rejected',
      4: 'Publishing Closed',
    },
  },
  ad_apply_bank: {
    status: {
      0: 'Unpaid',
      1: 'Paid',
    },
  },
  forum: {
    type: {
      1: 'Rental',
      2: 'Purchase Property',
      3: 'Newly Built Property',
    },
    tag: {
      0: 'General',
      1: 'Featured',
    },
    status: {
      0: 'Publishing',
      1: 'Self-Closed',
      2: 'Admin-Closed',
    },
    close_reason: {
      1: 'Not Related to Property',
      2: 'Duplicate Listing',
      3: 'Other',
    },
  },
  forum_comment: {
    status: {
      0: 'Normal',
      1: 'Self-Deleted',
      2: 'Admin-Deleted',
    },
    del_reason: {
      1: 'Not Related to Property',
      2: 'Duplicate Comment',
      3: 'Other',
    },
  },
  house: {
    type: {
      1: 'House for rental',
      2: 'House for sale',
    },
    registration: {
      0: 'Transfer Contract',
      1: 'Pink Book',
    },
    saleRegistration: {
      0: 'Transfer Contract',
      1: 'Pink Book',
    },
    status: {
      0: 'Not Published',
      1: 'Expired',
      2: 'Self-Closed',
      3: 'Customer Service Closed',
      4: 'Publishing',
      5: 'Sold Out',
    },
  },
  house_base_rule: {
    type: {
      1: 'Fire Safety',
      2: 'Tax Declaration',
      3: 'Household Registration',
      4: 'Altar Placement',
    },
  },
  house_equipment: {
    type: {
      1: 'Washing Machine',
      2: 'Refrigerator',
      3: 'Internet',
      4: 'TV',
      5: 'Cable TV',
      6: 'Water Heater',
      7: 'Air Conditioner',
      8: 'Natural Gas',
      9: 'Microwave',
      10: 'Bed',
      11: 'Wardrobe',
      12: 'Sofa',
      13: 'Tables and Chairs',
      14: 'Balcony',
      15: 'Parking Space',
      16: 'Elevator',
    },
  },
  house_files: {
    type: {
      1: 'Photos',
      2: 'Floor Plans',
      3: 'Videos',
      4: 'VR',
    },
  },
  house_fire_security: {
    type: {
      1: 'Fire Extinguisher',
      2: 'Fire Escape Chute',
      3: 'Lighting Equipment',
      4: 'Surveillance System',
    },
  },
  house_identity_rule: {
    type: {
      1: 'Office',
      2: 'Family',
      3: 'Student',
      4: 'Native',
      5: 'Foreigner',
    },
  },
  house_info: {
    house_category: {
      0: 'Unlimited',
      1: 'Studio',
      2: 'Shared Room',
      3: 'Independent',
      4: 'Whole Floor',
      5: 'Residential',
      6: 'Auction House',
      7: 'Other',
    },
    house_type: {
      0: 'Unlimited',
      1: 'Apartment',
      2: 'High-Rise Building',
      3: 'Detached House',
      4: 'Villa',
    },
    front: {
      0: 'Unlimited',
      1: 'East',
      2: 'South',
      3: 'West',
      4: 'North',
      5: 'Southeast',
      6: 'Southwest',
      7: 'Northeast',
      8: 'Northwest',
    },
    decoration: {
      0: 'Unknown',
      1: 'Not Decorated',
      2: 'Simple Decoration',
      3: 'Medium Decoration',
      4: 'High-end Decoration',
    },
    parking_type: {
      0: 'No Parking',
      1: 'Mechanical Parking',
      2: 'Surface Parking',
    },
    mng_fee_type: {
      0: 'No',
      1: 'Monthly',
      2: 'Every Two Months',
      3: 'Quarterly',
    },
    legally_usage: {
      0: 'Unknown',
      1: 'Residential',
      2: 'Commercial',
      3: 'Mixed Residential and Commercial',
      4: 'Industrial',
      5: 'Other',
    },
  },
  house_pet_rule: {
    type: {
      1: 'Cats Only',
      2: 'Dogs Only',
      3: 'Other',
    },
  },
  house_public_facility: {
    type: {
      1: 'Reception Hall',
      2: 'Sky Garden',
      3: 'Banquet Hall',
      4: 'Reading Room',
      5: 'Gym',
      6: 'KTV',
      7: 'Yoga Room',
      8: 'Mailbox Area',
      9: 'Drying Area',
      10: 'Barbecue Area',
    },
  },
  house_rent_pay_includes: {
    type: {
      1: 'Water Fee',
      2: 'Electricity Fee',
      3: 'Cable TV Fee',
      4: 'Internet Fee',
      5: 'Gas Fee',
      6: 'Management Fee',
    },
  },
  message: {
    type: {
      1: 'System',
      2: 'Purchase',
    },
    status: {
      0: 'Unpublished',
      1: 'Published',
    },
  },
  new_house: {
    case_type: {
      1: 'Pre-sale',
      2: 'Pre-sold',
      3: 'Newly Completed',
    },
    house_type: {
      1: 'Apartment',
      2: 'High-rise Building',
      3: 'Detached House',
      4: 'Villa',
    },
    sale_stat: {
      0: 'Not Started',
      1: 'On Sale',
      2: 'Sold Out',
    },
    status: {
      0: 'Not Posted',
      1: 'Posting',
      2: 'Customer Service Closed',
    },
    mng_fee_type: {
      1: 'Monthly',
      2: 'Every Two Months',
      3: 'Quarterly',
    },
    front: {
      0: 'Unlimited',
      1: 'East',
      2: 'South',
      3: 'West',
      4: 'North',
      5: 'Southeast',
      6: 'Southwest',
      7: 'Northeast',
      8: 'Northwest',
    },
    deal_stat: {
      0: 'Standard Furnishing',
      1: 'Simple Decoration',
      2: 'Bare House',
    },
  },
  new_house_files: {
    type: {
      1: 'Cover',
      2: 'Video',
      3: 'VR',
      4: 'Floor Plan',
      5: 'Transportation',
      6: 'Illustration',
      7: 'Actual Scene',
      8: 'Environment',
      9: 'Real Product',
    },
  },
  new_house_public_facility: {
    type: {
      1: 'Reception Hall',
      2: 'Sky Garden',
      3: 'Banquet Hall',
      4: 'Reading Room',
      5: 'Gym',
      6: 'KTV',
      7: 'Yoga Classroom',
      8: 'Mailbox Area',
      9: 'Sunbathing Area',
      10: 'Barbecue Area',
    },
  },
  factory: {
    type: {
      1: 'Factory',
      2: 'Office',
      3: 'Farmhouse',
      4: 'Depository',
      5: 'Office',
    },
    deposit: {
      1: 'A Month',
      2: 'Two Months',
      3: 'Three Months',
      4: 'Negotiable',
    },
    minLease: {
      1: 'One Year',
      2: 'Two Years',
      3: 'Three Years',
      4: 'Four Years',
      5: 'Five Years',
      6: 'Six Years',
      7: 'Seven Years',
      8: 'Eight Years',
      9: 'Nine Years',
      10: 'Ten Years',
      11: 'Negotiable',
    },
    partitions: {
      1: 'To be determined',
      2: 'In industrial area',
      3: 'Outside industrial area',
    },
    structure: {
      1: 'RC',
      2: 'Steel',
      3: 'Bricks and stones',
      4: 'Mixed',
      5: 'Brick and wood',
      6: 'Wooden',
    },
    unloading: {
      1: 'One side',
      2: 'Two sides',
      3: 'Three sides',
      4: 'All sides',
    },
    registration: {
      1: 'Yes',
      2: 'No',
      3: 'To be determined',
    },
    industry: {
      0: 'No Limit',
      1: 'Finance and Insurance',
      2: 'Consultation service',
      3: 'IT technology',
      4: 'Culture Media',
      5: 'Education and Training',
      6: 'Automobile Manufacturing',
      7: 'Digital Electronics',
      8: 'Trade and Retail',
      9: 'Service',
      10: 'Medical chemicals',
      11: 'High-tech Industries',
      12: 'Information and Communication',
      13: 'FMCG',
      14: 'Estate',
      15: 'Games and Entertainment',
      16: 'Other',
    },
    disabledIndustry: {
      0: 'All',
      1: 'Polluting',
      2: 'Chemical',
      3: 'Noise',
      4: 'Other',
    },
    basic_equipment: {
      0: 'All',
      1: '380V three-phase electricity',
      2: 'Parking',
      3: 'Natural Gas',
      4: 'Internet',
      5: 'Air Conditioner',
      6: 'Dormitory',
      7: 'Canteen',
    },
    handling_equipment: {
      0: 'All',
      1: 'Freight Elevator',
      2: 'Backup Generator',
      3: 'Elevator',
      4: 'Crane',
      5: 'Stacker',
      6: 'Electric Forklift',
      7: 'Pallet',
      8: 'Shelves',
      9: 'Sorting Line',
    },
  },
  land: {
    type: {
      1: 'Residential',
      2: 'Commercial',
      3: 'Industrial',
      4: 'Construction',
      5: 'Agricultural',
      6: 'Forestry',
      7: 'Other',
    },
    purpose: {
      0: 'No Limit',
      1: 'Farming',
      2: 'House',
      3: 'Processing factory',
      4: 'SMEs',
      5: 'High technology',
      6: 'Factory',
      7: 'Depository',
      8: 'Advertisement',
      9: 'Store',
    },
    infrastructure: {
      0: 'All',
      1: 'Electricity',
      2: 'Water',
      3: 'Pathway',
      4: 'Flat ground',
      5: 'Network cable',
    },
  },
  office: {
    category: {
      1: 'Office',
      2: 'Residential office',
    },
    type: {
      1: 'Apartment',
      2: 'Villa',
      3: 'Detached House',
      4: 'Store',
      5: 'High-rise Building',
    },
    tax: {
      0: 'Exclude',
      1: 'Include',
    },
    electricity: {
      0: 'Excluding electricity fee',
      1: 'Included Electricity fee',
    },
    registration: {
      0: 'To be determined',
      1: 'Yes',
      2: 'No',
    },
    equipment: {
      1: 'Internet',
      2: 'Office Furniture',
      3: 'Air Conditioner',
      4: 'Individual Washroom',
      5: 'Security Guard',
      6: 'Staff Restaurant',
      7: 'Transceiver Express',
      8: 'Visitor Reception',
      9: 'Cleaning Service',
      10: 'Negotiation Room',
      11: 'Meeting Room',
      12: 'Book Bar',
      13: 'Leisure Area',
      14: 'Multifunctional Hall',
      15: 'Pantry',
      16: 'Self-service Vending Machines',
      17: 'Refrigerator',
      18: 'Micro-wave oven',
      19: 'Water Purifier',
      20: 'Air Cleaner',
      21: 'WIFI',
      22: 'Fitness Equipment',
      23: 'Copy/Print Equipment',
    },
  },
  shop: {
    category: {
      1: 'Commercial Street Store',
      2: 'Commercial Residence',
      3: 'Corporate Office',
      4: 'Shopping Mall/Department Store',
      5: 'Roadside/Street Shops',
      6: 'Stalls',
      7: 'Transportation Facilities Shops',
      8: 'Other',
    },
    type: {
      1: 'Apartment',
      2: 'Villa',
      3: 'Detached House',
      4: 'Store',
      5: 'High-rise Building',
    },
    business_status: {
      1: 'Idling',
      2: 'Operating',
      3: 'Renting',
    },
    disabled: {
      1: 'Oil Fume Catering Industry',
      2: 'Special Industries',
      3: 'Claw Machine',
      4: 'Pet Shop',
      5: 'Car Wash Industry',
      6: 'Amusement Park',
      7: 'Religious Groups',
    },
    customer_flow: {
      1: 'Office Worker',
      2: 'Nearby Residents',
      3: 'Tourists',
    },
    equipment: {
      1: 'Tap Water',
      2: 'Sewage Treatment',
      3: '380V three-phase electricity',
      4: 'Smoke Exhaust Pipe',
      5: 'Sewage Pipe',
      6: 'Natural Gas',
      7: 'Air Conditioner',
      8: 'Internet',
      9: 'Individual Washroom',
      10: 'Independent Entrance And Exit',
      11: 'Water Heater',
    },
  },
  ord: {
    status: {
      0: 'Unpaid',
      1: 'Remitted',
      2: 'Paid',
      3: 'No received',
      4: 'Failed',
    },
    pay_type: {
      0: 'Manual',
      1: 'Remitted',
      2: 'Third party',
      3: 'Activity settlement',
      4: 'Actual Price Registration Bonus',
    },
  },
  ord_expose: {
    type: {
      1: 'Web Version',
      2: 'Mobile Version',
    },
  },
  plan_ad: {
    block: {
      1: 'Sidebar',
      2: 'Pop-Ups',
      3: 'Float',
      4: 'Carousel',
    },
    type: {
      7: 'Sidebar Advertise',
      8: 'Pop-ups Advertise',
      9: 'Float Advertise',
      10: 'Carousel Advertise',
    },
    status: {
      0: 'Not sent for review',
      1: 'Review',
      2: 'Approve',
      3: 'Decline',
      4: 'Close',
      5: 'All',
    },
  },
  plan_bonus: {
    category: {
      1: 'Update Sorting',
      2: 'Advertising Services',
      3: 'Labeling Services',
    },
    type: {
      1: {
        1: 'Manual Update',
        2: 'Automatic Update',
        3: 'Regular Update',
      },
      2: {
        1: 'Preferred Housing',
        2: 'Recommended Housing',
      },
      3: {
        1: 'Pin',
        2: 'Choice',
        3: 'Expedited',
      },
    },
    status: {
      0: 'Disable',
      1: 'Enable',
    },
  },
  plan_lessor: {
    type: {
      1: 'Rental Plan',
      2: 'Purchase Plan',
      3: 'Single Rental Plan',
      4: 'Single Sale Plan',
    },
    status: {
      0: 'Disable',
      1: 'Enable',
    },
  },
  plan_premium: {
    type: {
      5: 'Ad-Free Plan',
      6: 'Agent-Free Plan',
    },
    status: {
      0: 'Disable',
      1: 'Enable',
    },
  },
  rating: {
    type: {
      1: 'Landlord/Agent',
      2: 'Store',
    },
  },
  store: {
    status: {
      0: 'Disable',
      1: 'Enable',
    },
  },
  publish_house_sort: {
    type: {
      1: 'Latest Publish Time',
      2: 'Earliest Publish Time',
      3: 'Latest Creation Time',
      4: 'Earliest Creation Time',
    },
  },
  publish_type: {
    type: {
      1: 'Rent',
      2: 'Sale',
      3: 'Rent',
      4: 'Sale',
      5: 'Rent',
      6: 'Sale',
      7: 'Rent',
      8: 'Sale',
      9: 'Rent',
      10: 'Sale',
    },
  },
  activity_type: {
    type: {
      1: 'Brokerage',
      2: 'Facebook promotion',
      3: 'Zola promotion',
      4: 'Blogger/KOL',
    },
  },
  date_select: {
    1: 'Today',
    2: 'Week',
    3: 'Month',
    4: 'All',
  },
  task_status: {
    1: 'Incomplete',
    2: 'In progress',
    3: 'Completed',
    4: 'Cancel',
  },
  review_status: {
    1: 'Not Approved',
    2: 'Approved',
    3: 'Rejected',
  },
  branch_reply_status: {
    3: 'No Reply',
    4: 'Agree',
    5: 'Decline',
  },
  owner_type: {
    1: 'Seller',
    2: 'Builders',
    3: 'Brokerage Joint Sale',
  },
  appointment_type: {
    0: 'To Be Confirmed',
    1: 'Verbal',
    2: 'Generally',
    3: 'Full-time',
  },
  task_type: {
    1: 'Personal Visit',
    2: 'Phone',
    3: 'Visit House',
    4: 'Sign A Contract',
  },
  contract: {
    0: 'Contract has not been reviewed',
    1: 'Contract has been approved',
    2: 'Contract was not approved',
  },
  position: {
    1: 'Store Manager',
    2: 'Manager',
    3: 'Assistant Manager',
    4: 'Supervisor',
    5: 'Team Leader',
    6: 'Coordinator',
  },
  client_tabs: {
    0: 'All',
    1: 'Recently',
    2: 'No visit in two weeks',
  },
  assignment_status: {
    0: 'Unassigned',
    1: 'Assigned',
  },
  placeholder: {
    minimumPrice: 'Enter Minimum Price',
    maximumPrice: 'Enter Maximum Price',
    minimumArae: 'Enter Minimum Area',
    maximumArae: 'Enter Maximum Area',
    minimumFloor: 'Enter Minimum Floor',
    maximumFloor: 'Enter Maximum Floor',
    searchId: 'Enter ID',
    contractAmount: 'Please Enter The Service Fee',
    notApproved: 'Please Enter The Reason',
    selectAnAccount: 'Please select an account',
    selectIdentity: 'Please select the identity to be published',
  },
  alertText: {
    phone: 'Please enter a phone number',
    pwd: {
      error: 'Please enter a password of 8 to 12 characters including alphanumeric characters',
      over: 'Password exceeds 12 characters',
      noMatch: 'Does not match the password',
    },
    point: 'If there are insufficient points, you will be directed to point management.',
    publishPriceErr: 'The minimum amount is not less than 23000',
    publishDayErr: 'The number of days cannot be less than 0',
    areaErr: 'Please select at least one',
    logoutFail: 'Logout failed',
    planRoleNotPermit: 'Your current account identity cannot use this solution, please switch to another identity and try',
    loginFirst: 'Please log in first',
    pleaseCheck: 'Check please',
    over50mb: 'Upload file should not exceed 50MB',
    over25Pics: 'Up to 25 photos can be uploaded, please select again',
    maxPic: 'Upload has reached the maximum number',
    certType: 'Please choose certificate type',
    country: 'Please select province, county or city',
    area: 'Please select region',
    companyName: 'Please enter company name',
    branch: 'Please enter branch name',
    flyersEmpty: 'Please tick the houses for pre-made flyers',
    selectRating: 'Please select a rating',
    downloadFlyers: 'To download the flyer, please go to records',
    rechargePoints: 'Please enter recharge points',
  },
  noticeText: {
    loginAgain: 'Please log in again',
    addFavorite: 'Added to Favorites',
    removeFavorite: 'Removed from Favorites',
    loginSuccess: 'Login Successful',
    logoutSuccess: 'Logout Successful',
    modifiedSuccessfully: 'Modified Successfully',
    storeEnabled: 'Store is enabled',
    storeClosed: 'Store is closed',
    question: 'Question has been sent',
    closeHouseSuccess: 'Closed Successfully',
    dealHouseSuccess: 'Sold Successfully',
    sendSuccess: 'Sent Successfully',
    updateSortSuccess: 'Updated Successfully',
    buySuccess: 'Purchased Successfully',
    remittanceSuccess: 'After successful purchase, please go to the transaction management to fill in the remittance information after remittance to confirm whether the points have been remitted.',
    addSubAccountSuccess: 'Added sub-account successfully',
    accountIsEnabled: 'Account is enabled',
    accountIsDisabled: 'Account has been disabled',
    deleteMsgSuceess: 'Message has been deleted',
    sendMsgSuccess: 'Reply has been sent',
    reportSuccess: 'Report has been sent successfully',
    openingSoon: 'Opening soon',
    certificationSuccess: 'Submitted successfully, please wait for review within 24 hours',
    flyersSuccess: 'The flyer was successfully created',
    evaluationSuccess: 'Evaluation has been sent',
    copiedSuccess: 'Copied successfully',
    advertisementSuccess: 'Image advertising application is successful. The review time is about 1-3 working days. If you have any questions, please contact customer service.',
    publishArticleSuccess: 'Published article successfully',
    deleteArticleSuceess: 'Article deleted',
    modifyReplySuccess: 'Modified reply successfully',
    deleteReplySuceess: 'Reply deleted',
    subSuccess: 'Subscribe successfully',
    verficationCodeSuccess: 'Send Verification Code',
    saveSuccess: 'Added successfully',
    subReportSuccess: 'Report successfully',
    deleteTaskSuccess: 'Delete successfully',
    auditSuccess: 'Audit successfully',
    openSubscriptionSuccess: 'Automatically renewed is enabled',
    closeSubscriptionSuccess: 'Automatically renewed is turned off',
    checkInSuccess: 'Check in successfully',
    uploadSuccess: 'Upload Successfully',
  },
  success: {
    save: 'Saved successfully',
    purchasePlane: 'If the purchase is successful, you will be directed to solution management.',
  },
  noDataText: {
    noData: 'No Data Available',
    noMsg: 'No message',
    noComments: 'No comments',
    noLandMark: 'No nearby landmarks',
    noPromotions: 'No promotional activities',
    noRecord: 'No viewing history',
    noClient: 'Client information not obtained',
  },
  popularArea: [
    {
      name: 'Ho Chi Minh City',
      en: 'Thành phố Hồ Chí Minh',
    },
    {
      name: 'Ha Noi City',
      en: 'Thành phố Hà Nội',
    },
    {
      name: 'Hai Phong City',
      en: 'Thành phố Hải Phòng',
    },
    {
      name: 'Can Tho City',
      en: 'Thành phố Cần Thơ',
    },
    {
      name: 'Da Nang City',
      en: 'Thành phố Đà Nẵng',
    },
    {
      name: 'Dong Nai Province',
      en: 'Tỉnh Đồng Nai',
    },
    {
      name: 'Binh Duong Province',
      en: 'Tỉnh Bình Dương',
    },
  ],
};

export default en;
